import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Breadcrumbs, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import styles from "@/styles/FileManager/FileManager.module.scss";
import { fileTypes, TConnections } from "@/types/FileManager";
import { deleteFile, getFileDownloadUrl } from "@/utils/Files";
import { useMessages } from "@/utils/MessagesProvider";
import { makeSwrRequest } from "@/utils/SwrProvider";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDialog from "../Generic/Dialog";
import GenericProgress from "../Generic/Progress";
import GenericPrompt from "../Generic/Prompt";
import GenericTextField from "../Generic/TextField";
import Uploader from "../Upload/Uploader";
import { DroppableForm } from "../Upload/DroppableForm";

interface IFileManager {
    type?: fileTypes;
    recordId: string | number | null;
}

const FileManager = ({ type, recordId }: IFileManager) => {
    const { t } = useTranslation(["common", "files"]);
    type = !type ? "all" : type;
    recordId = !recordId ? null : recordId;

    const { showMessage } = useMessages();
    const [createFolderOpen, setCreateFolderOpen] = useState(false);
    const [uploadFileOpen, setUploadFileOpen] = useState(false);

    const [folderId, setFolderId] = useState<string | null>(null);

    const filesListUrlQuery = [];

    if (folderId !== "null") {
        filesListUrlQuery.push(`parentId=${folderId}`);
    }
    if (type !== "all" && recordId) {
        filesListUrlQuery.push(`type=${type}`);
        filesListUrlQuery.push(`recordId=${recordId}`);
    }

    const {
        data: parentData,
        error: parentError,
        isValidating: loadingParentData,
    } = makeSwrRequest(`/api/files/${folderId}`, "GET");
    const {
        data,
        isValidating: loadingData,
        mutate: refreshFiles,
    } = makeSwrRequest(`/api/files/?${filesListUrlQuery.join("&")}`, "GET");

    const changeFolder = (folderId: string | null) => {
        setFolderId(folderId);
    };

    const connections: TConnections = {
        type: type,
        recordId: recordId,
    };

    const createFolder = (folderName: string) => {
        const connection =
            type && recordId
                ? {
                    [`${type}_id`]: recordId,
                }
                : null;
        fetch("/api/files/create", {
            method: "POST",
            body: JSON.stringify({
                type: "folder",
                parentId: folderId,
                folderName: folderName,
                connections: connection,
            }),
        })
            .then((res) => res.json())
            .then((dataRes: any) => {
                refreshFiles();
                showMessage("success", t("files:folderCreated"));
            });
    };

    const downloadFile = (fileId: string) => {
        getFileDownloadUrl(fileId).then((url: string) => {
            setDialogDownloadUrl(url);
            setOpenDownloadDialog(true);
            return true;
        });
    };

    const currentFolderName =
        parentData?.name?.length == 0 ? `Root` : parentData?.name || "Root";
    const isLoading = loadingParentData && loadingData;

    const createRoute: any = (item: any) => {
        const result = [];

        if (item !== undefined) {
            result.push(item);
        }
        if (item?.parent) {
            result.push(createRoute(item.parent)[0]);
        }
        return result.reverse();
    };

    const route = createRoute(parentData?.parent_directories);

    const [dialogDownloadUrl, setDialogDownloadUrl] = useState<string | null>(
        null
    );
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

    return (
        <div className={styles.file_manager_holder}>
            <DroppableForm
                connections={connections}
                onUpload={() => {
                    refreshFiles();
                    setUploadFileOpen(false);
                }}
            >
                <Stack
                    direction={`row`}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <div>
                        <p className={styles.title}>
                            {currentFolderName} ({type} {recordId}){" "}
                            {isLoading ? (
                                <GenericProgress size={`small`} />
                            ) : null}
                        </p>
                        <Breadcrumbs
                            separator="›"
                            aria-label="breadcrumb"
                            className={styles.path_route_holder}
                        >
                            <span
                                className={`${folderId !== null ? styles.clickable : ""
                                    }`}
                                onClick={() => {
                                    changeFolder(null);
                                }}
                            >
                                {t("common:info.root")}
                            </span>
                            {route?.map((routeItem: any, index: number) => {
                                return (
                                    <span
                                        className={`${routeItem?.id !== folderId
                                            ? styles.clickable
                                            : ""
                                            }`}
                                        key={`routeItem${index}`}
                                        onClick={() => {
                                            changeFolder(routeItem.id);
                                        }}
                                    >
                                        {routeItem?.name}
                                    </span>
                                );
                            })}
                        </Breadcrumbs>
                    </div>
                    <Stack direction={`row`} spacing={0}>
                        <GenericButton
                            title={t("files:addFolder")}
                            customIcon={<CreateNewFolderIcon />}
                            type={`add`}
                            onClick={() => {
                                setCreateFolderOpen(true);
                            }}
                        />
                        <GenericButton
                            title={t("files:uploadFile")}
                            customIcon={<UploadFileIcon />}
                            type={`add`}
                            onClick={() => {
                                setUploadFileOpen(true);
                            }}
                        />

                        <CreateNewFolder
                            open={createFolderOpen}
                            setOpen={setCreateFolderOpen}
                            onCreate={(folderName) => {
                                createFolder(folderName);
                            }}
                        />
                        <UploadNewFile
                            open={uploadFileOpen}
                            setOpen={setUploadFileOpen}
                            folderId={folderId}
                            refreshFiles={refreshFiles}
                            connections={connections}
                        />
                    </Stack>
                </Stack>
                {dialogDownloadUrl && (
                    <DownloadFileDialog
                        open={openDownloadDialog}
                        setOpen={setOpenDownloadDialog}
                        linkUrl={dialogDownloadUrl || ""}
                        onClose={() => {
                            setDialogDownloadUrl(null);
                        }}
                    />
                )}
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={styles.file_icon}
                                ></TableCell>
                                <TableCell>{t("common:info.name")}</TableCell>
                                <TableCell>{t("common:info.date")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        {!isLoading ? (
                            <TableBody>
                                {parentData?.id != null ? (
                                    <TableRow>
                                        <TableCell>
                                            <DriveFolderUploadIcon />
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <span
                                                className={styles.clickable}
                                                onClick={() => {
                                                    changeFolder(
                                                        parentData?.parent_directory_id
                                                    );
                                                }}
                                            >
                                                ...
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                                {data
                                    ? data?.map((item: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={`fileManagerItem${index}`}
                                            >
                                                <TableCell>
                                                    {item.type == "file" ? (
                                                        <InsertDriveFileIcon />
                                                    ) : (
                                                        <FolderIcon />
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <span
                                                        className={
                                                            styles.clickable
                                                        }
                                                        onClick={(e: any) => {
                                                            if (
                                                                item.type ==
                                                                "directory"
                                                            ) {
                                                                changeFolder(
                                                                    item?.id
                                                                );
                                                            } else {
                                                                downloadFile(
                                                                    item?.id
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs(
                                                        item.created_at
                                                    ).format(
                                                        "DD.MM.YYYY H:mm:ss"
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <RemoveFileButton
                                                        fileId={item?.id}
                                                        fileName={item?.name}
                                                        onDelete={() => {
                                                            refreshFiles();
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    : null}
                            </TableBody>
                        ) : (
                            <TableBody>
                                {[...Array(4)].map(
                                    (item: any, index: number) => {
                                        return (
                                            <TableRow key={`rowIndex${index}`}>
                                                <TableCell />
                                                <TableCell colSpan={3}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        style={{
                                                            width: "100%",
                                                            height: "40px",
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </DroppableForm>
        </div>
    );
};

export const DownloadFileDialog = ({
    open,
    setOpen,
    linkUrl,
    onClose,
}: {
    open: boolean;
    setOpen: (state: boolean) => void;
    linkUrl: string;
    onClose: () => void;
}) => {
    const { t } = useTranslation(["common", "files"]);

    return (
        <GenericDialog
            open={open}
            setOpen={setOpen}
            showCloseButton
            onClose={onClose}
        >
            <a href={linkUrl}>
                <GenericButton
                    type={`download`}
                    title={t("files:downloadFile")}
                    onClick={() => {
                        setOpen(false);
                    }}
                />
            </a>
        </GenericDialog>
    );
};

const RemoveFileButton = ({
    fileId,
    fileName,
    onDelete,
}: {
    fileId: string | number;
    fileName: string;
    onDelete: () => void;
}) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation(["common", "files"]);
    return (
        <>
            <GenericButton
                type={`remove`}
                onClick={() => {
                    setOpen(true);
                }}
                small
            />
            <GenericPrompt
                open={open}
                setOpen={setOpen}
                title={t("files:sure")}
                text={`${t("files:delete")} "${fileName}"?`}
                agreeAction={() => {
                    deleteFile(parseInt(`${fileId}`), (data_del) => {
                        console.log("data_del", data_del);
                        onDelete();
                    });
                }}
            />
        </>
    );
};

const CreateNewFolder = ({
    open,
    setOpen,
    onCreate,
}: {
    open: boolean;
    setOpen: (state: boolean) => void;
    onCreate: (folderName: string) => void;
}) => {
    const [folderName, setFolderName] = useState("");
    const { t } = useTranslation(["common", "files"]);
    return (
        <GenericDialog open={open} setOpen={setOpen} disableClickOutside={true}>
            <div className={styles.create_folder_dialog_holder}>
                <GenericTextField
                    fullWidth
                    label={t("files:folderName")}
                    value={folderName}
                    onChange={(e: any) => {
                        setFolderName(e.target.value);
                    }}
                />
                <Stack
                    direction={`row`}
                    justifyContent={`flex-end`}
                    className={styles.control_buttons}
                >
                    <GenericButton
                        type={`save`}
                        title={t("common:actions.save")}
                        onClick={() => {
                            onCreate(folderName);
                            setFolderName("");
                            setOpen(false);
                        }}
                    />
                    <GenericButton
                        type={`discard`}
                        title={t("common:actions.close")}
                        onClick={() => {
                            setFolderName("");
                            setOpen(false);
                        }}
                    />
                </Stack>
            </div>
        </GenericDialog>
    );
};

const UploadNewFile = ({
    open,
    setOpen,
    folderId,
    refreshFiles,
    connections,
}: {
    open: boolean;
    setOpen: (state: boolean) => void;
    folderId: string | null;
    refreshFiles: () => void;
    connections: TConnections;
}) => {
    return (
        <div className={styles.upload_file_holder}>
            <GenericDialog
                open={open}
                setOpen={setOpen}
                disableClickOutside={true}
                showCloseButton={true}
            >
                <Uploader
                    uploadDirectoryId={folderId}
                    connections={connections}
                    onUpload={() => {
                        refreshFiles();
                        setOpen(false);
                    }}
                />
            </GenericDialog>
        </div>
    );
};

export default FileManager;
