import { CircularProgress, Stack } from "@mui/material";

const GenericProgress = ({
    type,
    size,
    title,
}: {
    type?: "circular";
    size?: "tiny" | "small" | "normal" | undefined;
    title?: string;
    noGutters?: boolean;
}) => {
    return (
        <Stack
            direction={`row`}
            spacing={size === "tiny" ? 0 : 2}
            alignItems={`center`}
            justifyContent={`center`}
            style={{
                width: size === "tiny" ? `10px` : `auto`,
                height: size === "tiny" ? `10px` : `auto`,
            }}
        >
            {!type || type === "circular" ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transformOrigin: "50% 50%",
                        transform: `scale(${
                            size === "small"
                                ? `0.6`
                                : size === "tiny"
                                ? `0.4`
                                : `1.0`
                        })`,
                    }}
                >
                    <CircularProgress color={`secondary`} />
                </div>
            ) : null}
            {title ? <span>{title}</span> : null}
        </Stack>
    );
};

export default GenericProgress;
