import { Stack } from "@mui/system";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import { IBrand, IModel } from "@/types/Vehicle";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDialog from "../Generic/Dialog";
import GenericTextField from "../Generic/TextField";

const AddModel = ({
    brand,
    open,
    setOpen,
    onSave,
}: {
    brand: IBrand | null;
    open: boolean;
    setOpen: (state: boolean) => void;
    onSave: (data: IModel) => void;
}) => {
    const [name, setName] = useState("");
    const { t } = useTranslation(["common", "vehicles"]);

    const saveData = () => {
        fetch(`/api/vehicles/models`, {
            method: "POST",
            body: JSON.stringify({
                brand_id: brand?.id,
                name: name,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setName("");
                setOpen(false);
                onSave(data?.response);
            });
    };

    return (
        <GenericDialog
            open={open}
            setOpen={setOpen}
            title={t("common:actions.addModel")}
            showCloseButton
        >
            <Stack direction={`row`} spacing={2}>
                <GenericTextField
                    label={t("vehicles:modelName")}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    fullWidth
                />
                <GenericButton
                    type="save"
                    title={t("common:actions.save")}
                    onClick={() => {
                        saveData();
                    }}
                />
            </Stack>
        </GenericDialog>
    );
};

export default AddModel;
