import styled from "@emotion/styled";
import {
    AdminPanelSettings,
    Api,
    ChangeCircle,
    Edit,
    HolidayVillage,
    Workspaces,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FaceIcon from "@mui/icons-material/Face";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
    Badge,
    BadgeProps,
    Button,
    Chip,
    FormControlLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuItem as MuiMenuItem,
    Popover,
    Switch,
} from "@mui/material";
import { Stack } from "@mui/system";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { ReactNode, useMemo, useRef, useState } from "react";

import AddCompany from "@/components/Contacts/AddCompany";
import AddPerson from "@/components/Contacts/AddPerson";
import AddIssue from "@/components/Issues/AddIssue";
import styles from "@/styles/Layout.module.scss";
import { INotification } from "@/types/Notification";
import { useAuth } from "@/utils/AuthProvider";
import { useBranch } from "@/utils/BranchProvider";
import { makeSwrRequest } from "@/utils/SwrProvider";

import SwitchBranch from "./Branches/SwitchBranch";
import Issues from "./Issues/Issues";
import NotificationsList from "./Notifications/NotificationsList";
import AddVehicle from "./Vehicles/AddVehicle";

const Layout = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation("common");
    const { isAdmin, superAdminView } = useAuth();
    const { currentBranch } = useBranch();

    const router = useRouter();

    const menuItems = [
        {
            title: t("common:layout.home"),
            icon: <HomeIcon />,
            link: "/",
            production: true,
        },
        {
            title: t("common:info.contacts"),
            icon: <ImportContactsIcon />,
            link: "/contacts",
            production: true,
        },
        {
            title: t("common:layout.vehicles"),
            icon: <DirectionsCarIcon />,
            link: "/vehicles",
            production: true,
        },
        {
            title: t("common:layout.calendar"),
            icon: <CalendarMonthIcon />,
            link: "/calendar",
            production: true,
        },
        // {
        // 	title: "Leads",
        // 	icon: (<CallSplitIcon />),
        // 	link: "/leads",
        // 	production: true,
        // 	hide: !isAdmin
        // },
        // {
        // 	title: "Offers",
        // 	icon: (<LocalOfferIcon />),
        // 	link: "/offers",
        // 	production: true,
        // 	hide: !isAdmin,
        // },
        // {
        // 	title: "Jobs",
        // 	icon: (<WorkOutlineIcon />),
        // 	link: "/jobs",
        // 	production: true,
        // 	hide: !isAdmin
        // },
        {
            title: t("common:layout.business"),
            icon: <BusinessCenterIcon />,
            hasSubMenu: true,
            items: [
                {
                    title: t("common:layout.leads"),
                    icon: <CallSplitIcon fontSize="small" />,
                    link: "/leads",
                },
                {
                    title: t("common:offer.offers"),
                    icon: <LocalOfferIcon fontSize="small" />,
                    link: "/offers",
                },
                {
                    title: t("common:job.jobs"),
                    icon: <WorkOutlineIcon fontSize="small" />,
                    link: "/jobs",
                },
            ],
        },
        {
            title: t("common:layout.adminControls"),
            icon: <AdminPanelSettings />,
            production: true,
            hide: !isAdmin,
            hasSubMenu: true,
            items: [
                {
                    title: t("common:layout.priceItem"),
                    icon: <Edit fontSize="small" />,
                    link: "/manager/editation/job-price-items",
                },
                {
                    title: t("common:layout.expenseItem"),
                    icon: <Edit fontSize="small" />,
                    link: "/manager/editation/job-expense-items",
                },
                {
                    title: t("common:layout.categories"),
                    icon: <Edit fontSize="small" />,
                    link: "/manager/editation/issue-categories",
                },
                {
                    title: t("common:info.brands"),
                    icon: <Edit fontSize="small" />,
                    link: "/manager/editation/brands",
                },
                {
                    title: t("common:layout.users"),
                    icon: <GroupIcon fontSize="small" />,
                    link: "/users",
                },
                {
                    title: "Branches",
                    icon: <HolidayVillage fontSize="small" />,
                    link: "/manager/editation/branches",
                    hide: !superAdminView,
                },
                {
                    title: t("common:info.departments"),
                    icon: <Workspaces fontSize="small" />,
                    link: "/manager/editation/departments",
                },
                {
                    title: "Swagger",
                    icon: <Api fontSize="small" />,
                    link: "/openapi/swagger",
                    hide: !superAdminView,
                },
            ],
        },
        {
            title: t("common:layout.settings"),
            icon: <SettingsIcon />,
            link: "/settings",
            production: false,
            hide: true,
        },
    ];

    const isLoginPage = router.asPath.includes("login");

    return (
        <>
            <Head>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/favicons/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicons/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicons/favicon-16x16.png"
                />
                <link rel="manifest" href="/favicons/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/favicons/safari-pinned-tab.svg"
                    color="#ea560d"
                />
                <link rel="shortcut icon" href="/favicons/favicon.ico" />
                <meta name="msapplication-TileColor" content="#282828" />
                <meta
                    name="msapplication-config"
                    content="/favicons/browserconfig.xml"
                />
                <meta name="theme-color" content="#ffffff" />
            </Head>

            {!isLoginPage ? (
                <>
                    <div className={styles.layout}>
                        <div className={styles.main_controls_holder}>
                            <Stack direction={`column`}>
                                <div className={styles.logo}>
                                    <Image
                                        src={"/logo/performansion_logo.png"}
                                        alt={"Performansion logo"}
                                        fill
                                        priority={true}
                                        sizes="400px"
                                        style={{
                                            objectFit: "contain",
                                            objectPosition: "left center",
                                        }}
                                    />
                                </div>
                                {currentBranch ? (
                                    <small
                                        style={{
                                            color: `white`,
                                            marginTop: `-10px`,
                                        }}
                                    >
                                        {currentBranch?.name || ""}
                                    </small>
                                ) : null}
                            </Stack>
                            <div className={styles.separator} />
                            <div className={styles.first_level_menu_holder}>
                                {menuItems.map((item: any, index: number) => {
                                    return (
                                        <MainMenuItem
                                            key={`mainMenuItem${index}`}
                                            item={item}
                                        />
                                    );
                                })}
                            </div>
                            <div className={styles.tools_holder}>
                                <AddPopover />
                                <NotificationsItem />
                                <IssuesItem count={0} />
                                <ProfileMenu />
                            </div>
                        </div>
                        <div className={styles.content_holder}>{children}</div>
                    </div>
                </>
            ) : (
                children
            )}
        </>
    );
};

const MainMenuItem = ({ item }: { item: any }) => {
    const router = useRouter();
    const isActive = !item?.hasSubMenu
        ? router?.asPath?.split("/")[1] ==
          item?.link?.split("/")[1]?.replace("/", "")
        : item?.items?.some((subItem: any) =>
              router?.asPath.includes(subItem?.link)
          );

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    if (
        (item?.production == false && process.env.NODE_ENV !== "development") ||
        item?.hide == true
    ) {
        return null;
    }

    return (
        <>
            <MenuItemChip
                active={isActive}
                onClick={(e: any) => {
                    if (!item?.hasSubMenu) {
                        router.push({
                            pathname: item?.link,
                        });
                    } else {
                        setMenuAnchor(e.currentTarget);
                    }
                }}
                title={`${item.title}`}
            >
                {item.icon}
            </MenuItemChip>
            {item?.hasSubMenu ? (
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={() => {
                        setMenuAnchor(null);
                    }}
                >
                    {item?.items
                        ? item?.items
                              ?.map((subItem: any, index: number) => {
                                  if (subItem?.hide === true) {
                                      return null;
                                  }
                                  return (
                                      <Link
                                          key={`item-${index}`}
                                          href={subItem?.link}
                                      >
                                          <MenuItem
                                              onClick={() => {
                                                  setMenuAnchor(null);
                                              }}
                                          >
                                              <ListItemIcon>
                                                  {subItem.icon}
                                              </ListItemIcon>
                                              {subItem.title}
                                          </MenuItem>
                                      </Link>
                                  );
                              })
                              .filter((item: any) => item !== null)
                        : null}
                </Menu>
            ) : null}
        </>
    );
};

const MenuItemChip = ({
    active,
    title,
    children,
    onClick,
}: {
    active?: boolean;
    title: string;
    children: any;
    onClick?: (e: any) => void;
}) => {
    return (
        <Chip
            className={`${styles.menu_item} ${active ? styles.active : ""}`}
            onClick={onClick}
            icon={children}
            label={title}
        />
    );
};

const ProfileMenu = () => {
    const auth = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [switchBranchOpen, setSwitchBranchOpen] = useState<boolean>(false);
    const { t } = useTranslation(["common"]);

    return (
        <>
            <MenuItemChip
                onClick={(e: any) => {
                    setAnchorEl(e.currentTarget);
                }}
                title={`${auth?.userData?.email || t("common:layout.profile")}`}
            >
                <FaceIcon />
            </MenuItemChip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                <MenuItem
                    onClick={() => {
                        setSwitchBranchOpen(true);
                    }}
                >
                    <ListItemIcon>
                        <ChangeCircle fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Switch branch</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        auth.logout();
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.logout")}</ListItemText>
                </MenuItem>
            </Menu>
            <SwitchBranch
                open={switchBranchOpen}
                setOpen={setSwitchBranchOpen}
            />
        </>
    );
};

const AddPopover = () => {
    const router = useRouter();

    const addButtonRef = useRef(null);
    const [open, setOpen] = useState(false);

    const [newIssueInitialType, setNewIssueInitialType] = useState<
        string | null
    >(null);
    const [openAddIssue, setOpenAddIssue] = useState<boolean>(false);
    const [openAddLead, setOpenAddLead] = useState<boolean>(false);
    const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
    const [openAddPerson, setOpenAddPerson] = useState<boolean>(false);
    const [openAddVehicle, setOpenAddVehicle] = useState<boolean>(false);
    const { t } = useTranslation(["common"]);

    return (
        <>
            <IconButton
                ref={addButtonRef}
                className={`${styles.add_button}`}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <AddIcon />
            </IconButton>
            <AddIssue
                open={openAddIssue}
                setOpen={setOpenAddIssue}
                initialType={"task"}
                onAdd={() => {
                    router.reload();
                }}
            />
            <AddIssue
                open={openAddLead}
                setOpen={setOpenAddLead}
                initialType={"lead"}
                onAdd={() => {
                    router.reload();
                }}
            />
            <AddCompany
                open={openAddCompany}
                setOpen={setOpenAddCompany}
                onAdd={() => {
                    //router.reload();
                }}
            />
            <AddPerson
                open={openAddPerson}
                setOpen={setOpenAddPerson}
                onAdd={() => {
                    router.reload();
                }}
            />
            <AddVehicle
                open={openAddVehicle}
                setOpen={setOpenAddVehicle}
                onAdd={() => {
                    router.reload();
                }}
            />

            <Menu
                anchorEl={addButtonRef.current}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <MuiMenuItem
                    onClick={() => {
                        setNewIssueInitialType("lead");
                        setOpenAddLead(true);
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <CallSplitIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.addLead")}</ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        router.push({
                            pathname: "/offers/add",
                        });
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <LocalOfferIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.addOffer")}</ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        router.push({
                            pathname: "/jobs/add",
                        });
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <WorkOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.addJob")}</ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        setOpenAddIssue(true);
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <LightbulbIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.addTask")}</ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        setOpenAddPerson(true);
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <PersonAddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t("common:layout.addPersonContact")}
                    </ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        setOpenAddCompany(true);
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <AddBusinessIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t("common:layout.addCompanyContact")}
                    </ListItemText>
                </MuiMenuItem>
                <MuiMenuItem
                    onClick={() => {
                        setOpenAddVehicle(true);
                        setOpen(false);
                    }}
                >
                    <ListItemIcon>
                        <DirectionsCarIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:layout.addVehicle")}</ListItemText>
                </MuiMenuItem>
            </Menu>
        </>
    );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: 10,
        top: 13,
        border: `2px solid var(--primary_color)`,
        padding: "0 4px",
        pointerEvents: "none",
    },
}));

const NotificationsItem = () => {
    const [openNotifications, setOpenNotifications] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);

    const notificationsButtonRef = useRef(null);

    const { t } = useTranslation(["common"]);

    const { data: notifications } = makeSwrRequest(
        `/api/notifications/`,
        "GET",
        {
            refreshInterval: 10000,
        }
    );

    const unreadNotifications = useMemo(() => {
        return notifications?.reduce(
            (currentCount: number, item: INotification) => {
                return currentCount + (item?.read_at === null ? 1 : 0);
            },
            0
        );
    }, [notifications]);

    const notificationsData = useMemo(() => {
        const data = showUnreadOnly
            ? notifications?.filter((item: INotification) => {
                  return item.read_at === null;
              })
            : notifications;
        return data?.slice(0, !showAll ? 10 : notifications.length - 1);
    }, [notifications, showAll, showUnreadOnly]);

    return (
        <>
            <StyledBadge badgeContent={unreadNotifications} color="secondary">
                <IconButton
                    ref={notificationsButtonRef}
                    onClick={() => {
                        setOpenNotifications(true);
                    }}
                    className={styles.white}
                >
                    <NotificationsIcon />
                </IconButton>
            </StyledBadge>
            <Popover
                open={openNotifications}
                anchorEl={notificationsButtonRef.current}
                onClose={() => {
                    setOpenNotifications(false);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                disablePortal={true}
            >
                <div className={styles.notifications_holder}>
                    <Stack direction={`row`} justifyContent={`space-between`}>
                        <h3>{t("common:layout.notification")}</h3>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked
                                    value={showUnreadOnly}
                                    onChange={() => {
                                        setShowUnreadOnly(!showUnreadOnly);
                                    }}
                                />
                            }
                            label={t("common:layout.showUnread")}
                        />
                    </Stack>
                    <NotificationsList notifications={notificationsData} />
                    {!notificationsData || notificationsData?.length == 0
                        ? `No ${
                              notificationsData &&
                              notificationsData?.length == 0
                                  ? `unread `
                                  : null
                          }notifications`
                        : null}
                    <Button
                        fullWidth
                        onClick={() => {
                            setShowAll(!showAll);
                        }}
                    >
                        {!showAll
                            ? t("common:layout.showAll")
                            : t("common:layout.showLess")}
                    </Button>
                </div>
            </Popover>
        </>
    );
};

const IssuesItem = ({ count }: { count: number | undefined }) => {
    const [issuesOpen, setIssuesOpen] = useState(false);

    const { t } = useTranslation("common");

    return (
        <span className={styles.issues_holder}>
            <Badge
                color={"error"}
                badgeContent={count}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Chip
                    icon={<LightbulbIcon className={styles.white} />}
                    label={t("common:info.case")}
                    onClick={() => {
                        setIssuesOpen(true);
                    }}
                    className={styles.white}
                />
            </Badge>
            <Issues open={issuesOpen} setOpen={setIssuesOpen} />
        </span>
    );
};

export default Layout;
