import { Stack, Tooltip } from "@mui/material";
import GenericDialog from "../Generic/Dialog";

import styles from '@/styles/Branches/SwitchBranch.module.scss';
import { IBranch } from "@/types/Branch";
import { Check } from "@mui/icons-material";
import { useRouter } from "next/router";
import { makeSwrRequest } from "@/utils/SwrProvider";
import { useAuth } from "@/utils/AuthProvider";
import { useMemo } from "react";
import { useLoading } from "@/utils/LoadingProvider";
import { useBranch } from "@/utils/BranchProvider";

const SwitchBranch = ({ open, setOpen }: {
	open: boolean;
	setOpen: (state: boolean) => void;
}) => {
	//const { t } = useTransition("switch-branch");

	const { showGlobalLoading, hideGlobalLoading } = useLoading();

	const router = useRouter();
	const { userData } = useAuth();

	const { currentBranch } = useBranch();
	const currentBranchId = currentBranch?.id;

	const branches = userData?.branches || [];
	const activeBranch = currentBranchId || 1;

	const switchBranch = (branch: IBranch) => {
		showGlobalLoading();
		setOpen(false);
		fetch(`/api/branches/${branch?.id}/switch-branch`, {
			method: 'PUT',
			body: JSON.stringify({
				preferredLanguage: branch?.language || "en"
			})
		}).then(res => res.json()).then((data) => {
			// router.push(`/`, undefined, {
			// 	shallow: false
			// }).then(() => {
			// 	refreshCurrentBranchId();
			// });
			router.reload();
			hideGlobalLoading();
		})
	}

	return (
		<GenericDialog
			open={open}
			setOpen={setOpen}
			title={`Switch branch`}
			showCloseButton
		>
			<div className={styles.branches_holder}>
				<Stack direction={`column`} spacing={1}>
					{branches?.map((branch: IBranch) => {
						return (
							<BranchItem
								key={branch?.id}
								branch={branch}
								active={activeBranch === branch?.id}
								onClick={(branchId) => {
									switchBranch(branch);
								}}
							/>
						)
					})}
				</Stack>
			</div>
		</GenericDialog>
	)
}

const BranchItem = ({ branch, active, onClick }: {
	branch: IBranch;
	active: boolean;
	onClick: (branchId: number) => void;
}) => {
	return (
		<Tooltip title={!active ? `Switch branch to ${branch.name}` : ``} arrow>
			<div
				className={`${styles.branch_item} ${active ? styles.active : ``}`}
				onClick={() => {
					onClick(branch?.id)
				}}
			>
				<Stack direction={`row`} spacing={2} alignItems={`center`} justifyContent={`space-between`}>
					<span>
						<p className={styles.title}>{branch.name}</p>
						<p className={styles.locales}>{branch.language} &bull; {branch.currency}</p>
					</span>
					{active ?
						<Check className={styles.selected_icon} />
						: null}
				</Stack>
			</div>
		</Tooltip>
	)
}

export default SwitchBranch