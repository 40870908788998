import {
    Avatar,
    AvatarGroup,
    Badge,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { useState } from "react";

import styles from "@/styles/Notifications/Notifications.module.scss";
import { INotification } from "@/types/Notification";
import { useNotifications } from "@/utils/NotificationsProvider";

import IssueDetail from "../Issues/IssueDetail";

const NotificationsList = ({
    notifications,
}: {
    notifications: INotification[];
}) => {
    return (
        <List disablePadding className={styles.notifications}>
            {notifications?.map((item: INotification) => (
                <NotificationItem
                    item={item}
                    key={`notificationItem${item.id}`}
                />
            ))}
        </List>
    );
};

const NotificationItem = ({ item }: { item: INotification }) => {
    const [openDetail, setOpenDetail] = useState(false);

    const { readNotification } = useNotifications();

    const connectionType = (): string | null => {
        if (item?.connections?.issue_id !== null) {
            return "issue";
        } else if (item?.connections?.contact_id !== null) {
            return "contact";
        } else if (item?.connections?.user_id !== null) {
            return "user";
        } else if (item?.connections?.vehicle_id !== null) {
            return "vehicle";
        }

        return null;
    };

    return (
        <>
            <ListItem disableGutters className={styles.notification_item}>
                <ListItemButton
                    onClick={() => {
                        if (connectionType() !== null) {
                            setOpenDetail(true);
                        }
                        if (item?.read_at === null) {
                            readNotification(item?.id);
                        }
                    }}
                >
                    <div
                        className={`${styles.unread_dot} ${
                            item?.read_at === null ? styles.active : ``
                        }`}
                    />
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={
                            <AvatarGroup>
                                <Avatar
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        border: `2px solid white`,
                                        fontSize: `0.7rem`,
                                    }}
                                />
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        border: `2px solid white`,
                                        fontSize: `0.7rem`,
                                    }}
                                />
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        border: `2px solid white`,
                                        fontSize: `0.7rem`,
                                    }}
                                />
                            </AvatarGroup>
                        }
                    >
                        <Avatar alt="Travis Howard">Š</Avatar>
                    </Badge>
                    <ListItemText
                        primary={item?.title}
                        secondary={item?.content}
                        className={styles.text_holder}
                    />
                </ListItemButton>
            </ListItem>
            {connectionType() === "issue" && (
                <IssueDetail
                    open={openDetail}
                    setOpen={setOpenDetail}
                    issueId={item?.connections?.issue_id || 0}
                />
            )}
        </>
    );
};

export default NotificationsList;
