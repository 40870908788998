import { useTranslation } from "next-i18next";
import { useState } from "react";

import { fileTypes } from "@/types/FileManager";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDialog from "../Generic/Dialog";
import FileManager from "./FileManager";

interface IFilesButton {
    type: fileTypes;
    recordId?: any;
}

const FilesButton = ({ type, recordId }: IFilesButton) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation(["common", "files"]);

    return (
        <>
            <GenericDialog open={open} setOpen={setOpen} large={true}>
                <FileManager type={type} recordId={recordId} />
            </GenericDialog>
            <GenericButton
                type="list"
                small
                title={t("files:show")}
                onClick={() => {
                    setOpen(true);
                }}
            />
        </>
    );
};

export default FilesButton;
