import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";

import { countryList, CountryType } from "@/defs/Countries/CountryList";

import GenericTextField from "./TextField";

const GenericPhoneInput = ({
    countryCodeValue,
    phoneValue,
    onChangeCountryCode,
    onChangePhone,
    required,
    customValidator,
}: {
    countryCodeValue: string;
    phoneValue: string;
    onChangeCountryCode: (e: any) => void;
    onChangePhone: (e: any) => void;
    required?: boolean;
    customValidator?: () => boolean;
}) => {
    const { t } = useTranslation("common");
    return (
        <Stack
            direction={`row`}
            spacing={1}
            style={{
                width: "100%",
            }}
        >
            <PhoneExtensionSelect
                value={countryCodeValue}
                onChange={onChangeCountryCode}
                required={required}
                customValidation={customValidator}
            />
            <GenericTextField
                fullWidth
                label={t("common:form.phone")}
                value={phoneValue}
                onChange={onChangePhone}
                required={required}
                customValidation={customValidator}
            />
        </Stack>
    );
};

const PhoneExtensionSelect = ({
    value,
    onChange,
    required,
    customValidation,
}: {
    value: string;
    onChange: (value: string) => void;
    required?: boolean;
    customValidation?: () => boolean;
}) => {
    const { t } = useTranslation("common");

    const isOk = required
        ? customValidation
            ? customValidation()
            : value?.toString()?.length > 0
        : true;

    return (
        <Autocomplete
            style={{
                width: "190px",
            }}
            options={countryList}
            autoHighlight
            getOptionLabel={(option) => `+${option.phone} (${option.code})`}
            renderOption={(props, option) => {
                return (
                    <Box
                        component="li"
                        sx={{
                            "& > .flag_holder": {
                                mr: 2,
                                flexShrink: 0,
                                alignItems: "center",
                                display: "flex",
                            },
                        }}
                        {...props}
                    >
                        <div className={`flag_holder`}>
                            <Image
                                src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                                width={20}
                                height={20}
                                alt="icon"
                                objectFit="contain"
                                objectPosition={`center center`}
                            />
                        </div>
                        <div>
                            <small>
                                <b>+{option.phone}</b>
                                <br />
                                {option.label}
                            </small>
                        </div>
                    </Box>
                );
            }}
            value={
                countryList.find((item: CountryType) => {
                    return item.phone == value;
                }) || null
            }
            onChange={(event: any, newValue: CountryType | null) => {
                onChange(newValue?.phone || "");
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={`small`}
                    label={t("common:form.phoneCode")}
                    required={required}
                    error={!isOk}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};

export default GenericPhoneInput;
