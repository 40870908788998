import { useEffect, useRef, useState } from "react";

import styles from "@/styles/Vehicles/VehicleDetail.module.scss";

import { TConnections } from "../../types/FileManager";
import Uploader from "./Uploader";

type Props = {
    connections: TConnections;
    children: React.ReactNode;
    onUpload: (uploadResult: any) => void;
};

export const DroppableForm = ({ connections, children, onUpload }: Props) => {
    const [dragActive, setDragActive] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleDrop = (uploadResult: any) => {
        setDragActive(false);
        onUpload(uploadResult);
    };

    useEffect(() => {
        window.addEventListener("blur", () => setDragActive(false));
    }, []);

    useEffect(() => {
        if (containerRef.current?.clientHeight) {
            setContentHeight(containerRef.current?.clientHeight);
        }
    }, [containerRef.current?.clientHeight]);

    return (
        <div
            ref={containerRef}
            onDragLeave={() => setDragActive(false)}
            onDragEnter={() => setDragActive(true)}
        >
            {dragActive ? (
                <div
                    className={styles.uploader_holder}
                    style={{ height: contentHeight }}
                >
                    <Uploader
                        connections={connections}
                        uploadDirectoryId={null}
                        onUpload={handleDrop}
                    />
                </div>
            ) : (
                children
            )}
        </div>
    );
};
