import styles from '@/styles/Generic/SectionDivider.module.scss';
import { Divider } from '@mui/material';

const GenericSectionDivider = ({title,textAlign}:{title:string;textAlign?:'left' | 'center' | 'right';}) => {
	if(!textAlign) {
		textAlign = "left";
	}
	return (
		<Divider textAlign={textAlign} className={styles.divider}>{title}</Divider>
	)
}

export default GenericSectionDivider;