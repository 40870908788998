export const stripHtml = (content: any) => {
    return content.replace(/<[^>]+>/g, "");
};

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

export const getNameInitials = (name: string) => {
    const splitted = name.split(" ");
    const first = splitted[0].charAt(0);
    const second = splitted[1]?.charAt(0) || "";
    const third = splitted[2]?.charAt(0) || "";
    return `${first}${second}${third}`;
};

export const checkIfBase64 = (string: string) => {
    const regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return regex.test(string);
};
