import { useTranslation } from "next-i18next";

import { IIssue } from "@/types/Issue";

import IssueItem from "./IssueItem";

const IssuesList = ({ issues, mutate }: { issues: IIssue[]; mutate: any }) => {
    const { t } = useTranslation("issues");

    return (
        <>
            {issues.map((issue: IIssue, index: number) => (
                <IssueItem
                    key={`issue-item-${index}`}
                    issue={issue}
                    reloadIssues={mutate}
                />
            ))}
            {issues.length === 0 ? t("issues:noIssuesConnected") : null}
        </>
    );
};

export default IssuesList;
