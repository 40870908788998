import { createContext, ReactNode, useContext } from "react";

import { TConnectionItem } from "@/types/FileManager";
import { INotification } from "@/types/Notification";

import { useAuth } from "./AuthProvider";
import { makeSwrRequest } from "./SwrProvider";

const NotificationsContext = createContext({});

type Props = {
    children: ReactNode;
};

export const NotificationsProvider = ({ children }: Props) => {
    const { userId } = useAuth();
    const sendNotification = (
        recipientId: number,
        title: string,
        message: string,
        connections: TConnectionItem
    ) => {
        if (recipientId !== parseInt(userId)) {
            fetch(`/api/notifications`, {
                method: "POST",
                body: JSON.stringify({
                    user_id: recipientId,
                    title: title,
                    message: message,
                    connections,
                }),
            });
        }
    };

    const sendBulkNotifications = (
        userIds: number[],
        title: string,
        message: string,
        connections: TConnectionItem
    ) => {
        userIds.forEach((userId: number) => {
            sendNotification(userId, title, message, connections);
        });
    };

    const readNotification = (notificationId: number) => {
        fetch(`/api/notifications/read/${notificationId}`, {
            method: "PUT",
        }).then((res) => res.json());
    };

    return (
        <NotificationsContext.Provider
            value={{
                sendNotification,
                sendBulkNotifications,
                readNotification,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = (): any => useContext(NotificationsContext);
