import dayjs from "dayjs";

import { DATE_TIME_FORMAT } from "@/defs/global/global";
import { TConnections } from "@/types/FileManager";

export const deleteFile = (fileId: number, onDelete?: (data: any) => void) => {
    fetch(`/api/files/${fileId}`, {
        method: "DELETE",
    })
        .then((res) => res.json())
        .then((data) => {
            if (onDelete) {
                onDelete(data);
            }
        });
};

export const getFileDonwloadToken = (fileId: string) => {
    return new Promise<string>((resolve) => {
        fetch("/api/uploads/tusAuthToken", {
            method: "POST",
            body: JSON.stringify({
                type: "download",
                file_id: fileId,
            }),
        })
            .then((res) => res.json())
            .then((dataRes: any) => {
                const token = dataRes?.token;
                resolve(token);
            });
    });
};

export const getFileDownloadUrl = (fileId: string) => {
    return new Promise<string>((resolve) => {
        getFileDonwloadToken(fileId).then((token: string) => {
            const downloadUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT ||
                `https://api.crm.performansion.com`
                }/api/public/v1/file-download?token=${token}`;
            resolve(downloadUrl);
        });
    });
};

export const createPdfFilePayload = (
    fileName: string,
    html: string,
    authorId: number,
    connections: TConnections
) => {
    return {
        name: `${fileName.includes(".pdf") ? fileName : `${fileName}.pdf`}`,
        description: `Created at ${dayjs().format(DATE_TIME_FORMAT)}`,
        html: html,
        author_id: authorId || 1,
        connections: {
            [`${connections?.type}_id`]: connections?.recordId,
        },
        rename_on_duplicate_name: true,
    };
};
