import { TextField } from "@mui/material";
import { GenericInputTypes } from "helpers/Generic/Input";

import styles from "@/styles/Generic/Field.module.scss";

interface IGenericTextField {
    value: string;
    label: string;
    onChange: (e: any) => void;
    required?: boolean;
    customValidation?: () => boolean;
    className?: string;
    type?: GenericInputTypes;
    fullWidth?: boolean;
    multiline?: boolean;
    disabled?: boolean;
    endAdornment?: any;
}

const GenericTextField = ({
    value,
    label,
    onChange,
    required,
    customValidation,
    className,
    type,
    fullWidth,
    multiline,
    disabled,
    endAdornment,
}: IGenericTextField) => {
    const isOk = required
        ? customValidation
            ? customValidation()
            : value?.toString()?.length > 0
        : true;

    return (
        <TextField
            size="small"
            value={value || ""}
            label={`${label}`}
            onChange={onChange}
            className={`${styles.input_item} ${className}`}
            required={required}
            error={!isOk}
            inputProps={{ type: type }}
            fullWidth={fullWidth}
            disabled={disabled}
            InputProps={{
                endAdornment: endAdornment,
            }}
            multiline={multiline}
        />
    );
};

export default GenericTextField;
