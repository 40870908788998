import { useTranslation } from "react-i18next";

export interface IConnectionType {
    key: string;
    title: string;
}

const useConnectionTypes = () => {
    const { t } = useTranslation("issues");

    const connectionTypes: IConnectionType[] = [
        {
            key: "issue",
            title: t("issues:connectionType.issue"),
        },
        {
            key: "contact",
            title: t("issues:connectionType.contact"),
        },
        {
            key: "vehicle",
            title: t("issues:connectionType.vehicle"),
        },
        {
            key: "job",
            title: t("issues:connectionType.job"),
        },
    ];

    return connectionTypes;
};

export default useConnectionTypes;
