import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSWRConfig } from "swr";

import { makeSwrRequest } from "./SwrProvider";

const AuthContext = createContext({});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();

    const logout = () => {
        fetch("/api/auth/logout", {
            method: "POST",
        })
            .then((res) => res.json())
            .then(() => {
                router.push({
                    pathname: "/",
                });
                location.reload();
                return;
            });
        return;
    };

    const { data: apiStatus } = makeSwrRequest("/api/auth/checkAuth", "POST");

    const checkIfLoggedIn = () => {
        return apiStatus?.status || false;
    };

    const [userId, setUserId] = useState<string | null>(null);
    const {
        data: userData,
        mutate: refreshUserData,
        isValidating: userIsReloading,
    } = makeSwrRequest(userId ? `/api/users/${userId}` : null);
    const { cache } = useSWRConfig();

    useEffect(() => {
        getUserId();
    }, [router.asPath]);

    const getUserId = () => {
        fetch(`/api/auth/userId`, {
            method: "POST",
        })
            .then((res) => res.json())
            .then(({ userId }) => {
                if (userId === null && checkIfLoggedIn() === true) {
                    getUserId();
                } else {
                    setUserId(userId);
                }
            });
    };

    const isAdmin = useMemo(() => {
        return userData?.role == "admin";
    }, [userData]);

    useEffect(() => {
        getUserId();
        refreshUserData();
    }, [userId, checkIfLoggedIn()]);

    const superAdminView = isAdmin && userData.email.includes("apexmedia");

    return (
        <AuthContext.Provider
            value={{
                logout,
                checkIfLoggedIn,
                userData,
                userId: parseInt(userId || ""),
                userIsLoading: !userData?.id && userIsReloading,
                isAdmin,
                superAdminView,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): any => useContext(AuthContext);
