import { useTranslation } from "next-i18next";

import { IIssue } from "@/types/Issue";
import { makeSwrRequest } from "@/utils/SwrProvider";

import GenericDialog from "../Generic/Dialog";
import IssueItem from "./IssueItem";

interface IIssueDetail {
    open: boolean;
    setOpen: (state: boolean) => void;
    issue?: IIssue;
    issueId?: number;
    onChange?: () => void;
}
const IssueDetail = ({
    open,
    setOpen,
    issue,
    issueId,
    onChange,
}: IIssueDetail) => {
    const { t } = useTranslation(["common", "issues"]);

    const { data: fetchedIssue, mutate: refreshIssue } = makeSwrRequest(
        issueId ? `/api/issues/${issueId}` : null,
        `GET`,
        {
            refreshInterval: 30000,
        }
    );

    return (
        <GenericDialog
            open={open}
            setOpen={setOpen}
            title={t("issues:caseDetail")}
            showCloseButton={true}
            large
            lowLayer
        >
            <IssueItem
                issue={issue || fetchedIssue}
                expanded={true}
                reloadIssues={refreshIssue}
                onChange={() => {
                    if (onChange) {
                        onChange();
                    }
                }}
            />
        </GenericDialog>
    );
};

export default IssueDetail;
