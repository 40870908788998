import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";

import styles from "@/styles/Generic/Dialog.module.scss";

interface IGenericDialog {
    open: boolean;
    setOpen: (state: boolean) => void;
    disableClickOutside?: boolean;
    title?: string;
    children: any;
    showCloseButton?: boolean;
    large?: boolean;
    customWidth?: number;
    onClose?: () => void;
    lowLayer?: boolean;
}

const GenericDialog = ({
    open,
    setOpen,
    disableClickOutside,
    title,
    children,
    showCloseButton,
    customWidth,
    large,
    onClose,
    lowLayer,
}: IGenericDialog) => {
    return (
        <Dialog
            onClick={(event) => (event.target = document.body)}
            open={open}
            onClose={() => {
                if (!disableClickOutside) {
                    setOpen(false);
                }
                if (onClose) {
                    onClose();
                }
            }}
            fullWidth={large}
            sx={{
                zIndex: lowLayer ? 1199 : 1300,
            }}
            PaperProps={{
                sx: {
                    maxWidth: customWidth
                        ? `${customWidth}px`
                        : large
                        ? "1000px"
                        : "600px",
                },
            }}
        >
            <div
                className={`${styles.dialog_holder} ${
                    large ? styles.large : ""
                }`}
            >
                {showCloseButton ? (
                    <IconButton
                        className={styles.close_button}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <Close />
                    </IconButton>
                ) : (
                    ""
                )}
                {title ? <h2>{title}</h2> : null}
                {children}
            </div>
        </Dialog>
    );
};

export default GenericDialog;
