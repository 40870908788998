import Uppy from "@uppy/core";
import { DragDrop, FileInput, ProgressBar } from "@uppy/react";
import Tus from "@uppy/tus";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TConnections } from "@/types/FileManager";
import { useMessages } from "@/utils/MessagesProvider";
import { tusUploadEndpointUrl } from "@/utils/Uploads/Uploads";
import {
    generateUploadAuthToken,
    getUppyInstance,
    saveFile,
} from "@/utils/UploadsProvider";

import GenericProgress from "../Generic/Progress";

interface IUploader {
    small?: boolean;
    onlyClient?: boolean;
    uploadDirectoryId: string | null;
    connections: TConnections;
    onUpload: (uploadResult: any) => void;
    onThumbnailGenerated?: (uppy: Uppy, file: any, preview: any) => void;
    uppyRef?: any;
    autoProceed?: boolean;
}

const Uploader = ({
    small,
    uploadDirectoryId,
    connections,
    uppyRef,
    autoProceed,
}: IUploader) => {
    const [uploadToken, setUploadToken] = useState<string | null>(null);

    const { t } = useTranslation("common");

    const uppy = getUppyInstance(autoProceed);
    if (uppyRef && uppyRef?.current === null) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        uppyRef.current = useMemo(() => {
            return uppy;
        }, [uppy]);
    }

    const getUploadAuthToken = () => {
        generateUploadAuthToken().then((token: string) => {
            setUploadToken(token);
        });
    };

    // useEffect(() => {
    // 	if (uploadToken) {
    // 		setUppyProps(uppy, uploadToken);
    // 	}
    // }, [uppy, uploadToken]);

    useEffect(() => {
        getUploadAuthToken();
    }, []);

    uppy.on("file-added", (file) => {
        if (uploadToken === null) {
            getUploadAuthToken();
        }
    });

    if (uploadToken) {
        uppy.use(Tus, {
            endpoint: tusUploadEndpointUrl,
            retryDelays: [0, 1000, 3000, 5000],
            headers: {
                "tus-auth-token": uploadToken,
            },
        });

        uppy.on("complete", (result: any) => {
            const url = result?.successful[0]?.uploadURL;
            const uploadId = url.replace(`${tusUploadEndpointUrl}/`, "");
            const fileName = result?.successful[0]?.name;

            saveFile(uploadId, null, connections, fileName);
        });
    } else {
        getUploadAuthToken();
    }

    // if (uploadToken) {
    // 	uppy.on('complete', (result: any) => {
    // 		console.log("completeRES", result);
    // 		const { uploadId, fileName } = uploadCompleted(result);

    // 		saveFile(uploadId, uploadDirectoryId, connections, fileName).then((dataRes: any) => {
    // 			console.log("fileUploadRes: ", dataRes);
    // 			getUploadAuthToken();
    // 			if (dataRes?.err?.code == 400) {
    // 				showMessage('error', `${dataRes?.err?.message.toString()}`);
    // 			} else {
    // 				showMessage('success', `File (${fileName}) successfully uploaded`);
    // 				onUpload(dataRes);
    // 			}
    // 		});
    // 	});
    // }

    return (
        <>
            {uploadToken ? (
                !small ? (
                    <>
                        <h2>
                            {t("common:message.uploading")}{" "}
                            {uploadDirectoryId ? uploadDirectoryId : `root`}
                        </h2>
                        <DragDrop
                            uppy={uppy}
                            locale={{
                                strings: {
                                    dropHereOr: t("common:actions.drop"),
                                    browse: "browse",
                                },
                            }}
                        />

                        <ProgressBar uppy={uppy} fixed hideAfterFinish />
                    </>
                ) : (
                    <FileInput uppy={uppy} />
                )
            ) : (
                <GenericProgress size={`small`} />
            )}
        </>
    );
};

export default Uploader;
