import { useTranslation } from "next-i18next";
import { useState } from "react";

import GenericButton from "../Generic/Buttons/GenericButton";
import AddCompany from "./AddCompany";
import AddPerson from "./AddPerson";

const AddOwnerButton = ({ onAdd }: { onAdd: (data: any) => void }) => {
    const [addContactOpen, setAddContactOpen] = useState(false);
    const [addCompanyOpen, setAddCompanyOpen] = useState(false);

    const { t } = useTranslation(["common", "contacts"]);

    return (
        <>
            <GenericButton
                type={`add`}
                hasMenu={true}
                menuItems={[
                    {
                        title: t("contacts:addComp"),
                        onClick: () => {
                            setAddCompanyOpen(true);
                        },
                    },
                    {
                        title: t("contacts:addPerson"),
                        onClick: () => {
                            setAddContactOpen(true);
                        },
                    },
                ]}
            />
            <AddCompany
                open={addCompanyOpen}
                setOpen={setAddCompanyOpen}
                onAdd={(company) => {
                    onAdd(company);
                }}
            />
            <AddPerson
                open={addContactOpen}
                setOpen={setAddContactOpen}
                onAdd={(contact) => {
                    onAdd(contact);
                }}
            />
        </>
    );
};

export default AddOwnerButton;
