import { Button, Stack } from "@mui/material";
import { GenericInputTypes } from "helpers/Generic/Input";
import { useTranslation } from "next-i18next";
import { useMemo, useReducer, useState } from "react";

import { ACTIONS } from "@/defs/ManageVehicle/Vehicle";
import styles from "@/styles/Vehicles/AddVehicleDrawer.module.scss";
import { IBrand, IEngine, IModel, IVehicle } from "@/types/Vehicle";
import { useMessages } from "@/utils/MessagesProvider";
import { useBrands } from "@/utils/useBrands";

import AddBrand from "../Brands/AddBrand";
import AddEngine from "../Brands/AddEngine";
import AddModel from "../Brands/AddModel";
import GenericSectionDivider from "../Contacts/ContactSectionDivider";
import GenericAutoComplete from "../Generic/AutoComplete";
import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDrawer from "../Generic/Drawer";
import GenericTextField from "../Generic/TextField";

interface IAddVehicle {
    open: boolean;
    setOpen: (state: boolean) => void;
    onAdd?: (vehicle: IVehicle) => void;
}

const AddVehicle = ({ open, setOpen, onAdd }: IAddVehicle) => {
    const { t } = useTranslation(["common", "vehicles"]);

    const vehicleReducer = (state: any, action: any) => {
        switch (action.type) {
            case ACTIONS.SET_FIELD:
                return { ...state, [action.payload.key]: action.payload.value };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(vehicleReducer, {});

    const [openAddBrand, setOpenAddBrand] = useState(false);
    const [openAddModel, setOpenAddModel] = useState(false);
    const [openAddEngine, setOpenAddEngine] = useState(false);

    const [brandId, setBrandId] = useState(state?.brand?.id);
    const [modelId, setModelId] = useState(state?.model?.id);

    const { brands, models, engines, refreshAll } = useBrands(brandId, modelId);

    const selectedBrand = useMemo(() => {
        return (
            brands?.find((item: IBrand) => item?.id === parseInt(brandId)) ||
            null
        );
    }, [brands, brandId]);
    const selectedModel = useMemo(() => {
        return (
            models?.find((item: IModel) => item?.id === parseInt(modelId)) ||
            null
        );
    }, [models, modelId]);
    const selectedEngine = useMemo(() => {
        return (
            engines?.find((item: IEngine) => item.id === state?.engine_id) ||
            null
        );
    }, [engines, state, modelId]);

    const { showMessage } = useMessages();

    const sendData = () => {
        const finalData = {
            ...state,
            vin: state?.vin && state?.vin?.length > 0 ? state.vin : null,
        };

        fetch("/api/vehicles", {
            method: "POST",
            body: JSON.stringify(finalData),
        })
            .then((res) => res.json())
            .then((data_res: any) => {
                if (data_res?.response?.id) {
                    setOpen(false);
                    showMessage(
                        "success",
                        `${t("vehicles:id")} ${data_res?.response?.id} ${t(
                            "vehicles:added"
                        )}`
                    );
                    if (onAdd) {
                        onAdd(data_res?.response);
                    }
                } else {
                    showMessage(
                        "error",
                        `${
                            data_res?.response?.err?.message ||
                            t("common:message.failed")
                        }`
                    );
                }
            })
            .catch((e: any) => {
                showMessage(
                    "error",
                    `${t("common:message.failed")}: ${e.toString()}`
                );
            });
    };

    return (
        <GenericDrawer open={open} setOpen={setOpen}>
            <div className={styles.add_vehicle_drawer_holder}>
                <GenericSectionDivider title={t("vehicles:info")} />
                <div className={styles.input_item}>
                    <Stack direction={`row`} spacing={2}>
                        <GenericAutoComplete
                            label={t("vehicles:brandExample")}
                            options={brands || []}
                            selected_value={selectedBrand}
                            getOptionLabel={(option) => {
                                return `${option.name}`;
                            }}
                            renderOptionTitle={(option) => {
                                return `${option.name}`;
                            }}
                            onChange={(event, newValue) => {
                                setBrandId(newValue.id);
                                setModelId("");
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                        />
                        <GenericButton
                            type="add"
                            onClick={() => {
                                setOpenAddBrand(true);
                            }}
                        />
                        <AddBrand
                            open={openAddBrand}
                            setOpen={setOpenAddBrand}
                            onSave={(brand) => {
                                refreshAll().then(() => {
                                    setBrandId(`${brand?.id}`);
                                });
                            }}
                        />
                    </Stack>
                </div>
                <div className={styles.input_item}>
                    <Stack direction={`row`} spacing={2}>
                        <GenericAutoComplete
                            label={t("vehicles:modelExample")}
                            options={models || []}
                            selected_value={selectedModel}
                            getOptionLabel={(option) => {
                                return `${option.name}`;
                            }}
                            renderOptionTitle={(option) => {
                                return `${option.name}`;
                            }}
                            onChange={(event, newValue) => {
                                setModelId(newValue.id);
                                dispatch({
                                    type: ACTIONS.SET_FIELD,
                                    payload: {
                                        key: "engine_id",
                                        value: "",
                                    },
                                });
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                        />
                        {brandId ? (
                            <GenericButton
                                type="add"
                                onClick={() => {
                                    setOpenAddModel(true);
                                }}
                            />
                        ) : null}
                        <AddModel
                            brand={selectedBrand}
                            open={openAddModel}
                            setOpen={setOpenAddModel}
                            onSave={(model) => {
                                refreshAll().then(() => {
                                    if (model?.id !== undefined) {
                                        setModelId(`${model?.id}`);
                                    }
                                });
                            }}
                        />
                    </Stack>
                </div>
                <div className={styles.input_item}>
                    <Stack direction={`row`} spacing={2}>
                        <GenericAutoComplete
                            label={t("vehicles:engineExample")}
                            options={engines || []}
                            selected_value={selectedEngine}
                            getOptionLabel={(option) => {
                                return `${option.name}`;
                            }}
                            renderOptionTitle={(option) => {
                                return `${option.name}`;
                            }}
                            onChange={(event, newValue) => {
                                dispatch({
                                    type: ACTIONS.SET_FIELD,
                                    payload: {
                                        key: "engine_id",
                                        value: newValue.id,
                                    },
                                });
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                        />
                        {modelId ? (
                            <GenericButton
                                type="add"
                                onClick={() => {
                                    setOpenAddEngine(true);
                                }}
                            />
                        ) : null}
                        <AddEngine
                            model={selectedModel}
                            open={openAddEngine}
                            setOpen={setOpenAddEngine}
                            onSave={(engine) => {
                                refreshAll().then(() => {
                                    dispatch({
                                        type: ACTIONS.SET_FIELD,
                                        payload: {
                                            key: "engine_id",
                                            value: engine.id,
                                        },
                                    });
                                });
                            }}
                        />
                    </Stack>
                </div>
                {/* <VehicleTextField
						value={state?.engine_capacity}
						label={"Engine capacity"}
						onChange={(e) => {
							dispatch({
								type: ACTIONS.SET_FIELD,
								payload: {
									key: "engine_capacity",
									value: parseFloat(e.target.value)
								}
							})
						}}
						required={false}
						type={"number"}
					/> */}
                <VehicleTextField
                    value={state?.vin}
                    label={t("vehicles:vin")}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.SET_FIELD,
                            payload: {
                                key: "vin",
                                value: e.target.value,
                            },
                        });
                    }}
                    required={state?.vin && state?.vin?.length > 0}
                    customValidator={() => {
                        return state?.vin?.length == 17;
                    }}
                />
                <VehicleTextField
                    value={state?.license_plate}
                    label={t("vehicles:plate")}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.SET_FIELD,
                            payload: {
                                key: "license_plate",
                                value: e.target.value,
                            },
                        });
                    }}
                    required={false}
                />
                {/* <VehicleTextField
						value={state?.condition}
						label={"Condition"}
						onChange={(e) => {
							dispatch({
								type: ACTIONS.SET_FIELD,
								payload: {
									key: "condition",
									value: e.target.value
								}
							})
						}}
						required={false}
					/>
					<VehicleTextField
						value={state?.colour}
						label={"Colour"}
						onChange={(e) => {
							dispatch({
								type: ACTIONS.SET_FIELD,
								payload: {
									key: "colour",
									value: e.target.value
								}
							})
						}}
						required={false}
					/> */}
            </div>

            <Button
                variant={"outlined"}
                onClick={() => {
                    sendData();
                }}
            >
                {t("vehicles:save")}
            </Button>
        </GenericDrawer>
    );
};

interface IVehicleTextField {
    value: string;
    label: string;
    onChange: (e: any) => void;
    required?: boolean;
    type?: GenericInputTypes;
    customValidator?: () => boolean;
}

const VehicleTextField = ({
    value,
    label,
    onChange,
    required,
    type,
    customValidator,
}: IVehicleTextField) => {
    return (
        <GenericTextField
            value={value || ""}
            label={label}
            onChange={(e: any) => {
                onChange(e);
            }}
            className={styles.input_item}
            required={required}
            type={type}
            customValidation={customValidator}
        />
    );
};

export default AddVehicle;
