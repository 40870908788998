import { IIssue } from "@/types/Issue";
import { makeSwrRequest } from "./SwrProvider";

export const isUserAuthorizedToViewIssue = (issue: IIssue, userId: number) => {
	return (issue?.author?.id === userId || issue?.assignees?.some((assignee) => assignee?.id === userId || issue?.maintainer?.id === userId))
}

export const convertLeadToOffer = async (issueId: number) => {
	const res = await fetch(`/api/issues/convert-to-offer/${issueId}`, {
		method: 'POST'
	});
	return await res.json();
}

export const useIssues = (active: boolean): {
	issues: any,
	isLoading: boolean,
	isError: any,
	mutate: any,
	isValidating: any;
} => {
	const { data, error, mutate, isValidating } = makeSwrRequest(active ? '/api/issues/' : null, `GET`, {
		refreshInterval: 40000
	});

	return {
		issues: data,
		isLoading: !error && !data,
		isError: error,
		mutate: mutate,
		isValidating: isValidating,
	}
}