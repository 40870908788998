export const ACTIONS = {
	SET_FIELD:"set_field",
	SET_BASIC_INFORMATIONS:"set_basic_informations",
	SET_COMPANY_INFORMATION:"set_company_information",
	SET_CONTACT_PERSONS:"set_contact_persons",
	REMOVE_CONTACT_PERSON:"remove_contact_person",
	SET_PHONES:"set_phones",

	SET_CONTACT_INFO:"set_contact_info",
	SET_BANK_ACCOUNT_INFO:"set_bank_account_info",
	ADD_EMPTY_SHIPPING_ADDRESS:"add_empty_shipping_address",
	SET_SHIPPING_ADDRESS:"set_shipping_addresses",
	REMOVE_SHIPPING_ADDRESS:"remove_shipping_address",
	SET_BILLING_ADDRESS: "set_billing_addresses",
}