import { Close } from "@mui/icons-material";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";

const MessagesContext = createContext({});

type MessageTypes = "success" | "info" | "error" | "warning" | undefined;

export const MessagesProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [show, setShow] = useState(false);
    const [customMessage, setCustomMessage] = useState<string | undefined>("");
    const [messageType, setMessageType] = useState<MessageTypes>("info");

    const showMessage = (type: MessageTypes, message?: string) => {
        setCustomMessage(message);
        setShow(true);
        setMessageType(type);
    };

    return (
        <MessagesContext.Provider
            value={{
                showMessage,
            }}
        >
            <>
                {children}
                <AlertMessage
                    type={messageType}
                    open={show}
                    setOpen={setShow}
                    customMessage={customMessage}
                    setCustomMessage={setCustomMessage}
                />
            </>
        </MessagesContext.Provider>
    );
};

interface IAlertMessage {
    type?: MessageTypes;
    open: boolean;
    setOpen: (state: boolean) => void;
    customMessage?: string;
    setCustomMessage?: (message: string | undefined) => void;
}
const AlertMessage = ({
    type,
    open,
    setOpen,
    customMessage,
    setCustomMessage,
}: IAlertMessage) => {
    const closeMessage = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open === false) {
            if (setCustomMessage) {
                setCustomMessage(undefined);
            }
        }
    }, [open]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={() => {
                closeMessage();
            }}
            autoHideDuration={5000}
        >
            <Alert
                severity={type || "info"}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            closeMessage();
                        }}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                }
            >
                {customMessage ? customMessage : `Message`}
            </Alert>
        </Snackbar>
    );
};

export const useMessages = (): any => useContext(MessagesContext);
