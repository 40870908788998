import { useContext, createContext, useEffect } from 'react';
import { makeSwrRequest } from './SwrProvider';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';

const BranchContext = createContext({});

export const BranchProvider = ({ children }: any) => {

	const router = useRouter();
	const { data: currentBranchId } = makeSwrRequest(`/api/branches/get-current-branch`);
	const { data: currentBranch } = makeSwrRequest(currentBranchId ? `/api/branches/${currentBranchId?.id}` : null);

	const currentCurrency = currentBranch?.currency || "";
	const currentLang = currentBranch?.language || "en";

	/**
	 * avoid user switching to another language other than the defined one in branch
	 */
	useEffect(() => {
		i18n?.changeLanguage(currentLang);
		//router.push(`/${currentLang}${router.pathname}`);
		if (router.locale != currentLang) {
			router.push(router.asPath, router.asPath, {
				locale: currentLang
			})
		}
	});

	return (
		<BranchContext.Provider value={{
			currentBranch,
			currentLang,
			currentCurrency
		}}>
			{children}
		</BranchContext.Provider>
	)
}

export const useBranch = (): any => useContext(BranchContext);