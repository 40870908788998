import { MenuItem, Select, FormControl, InputLabel } from "@mui/material"

export type TData = {
	key: string;
	value: string;
}

interface IGenericSelect {
	label: string;
	value: string;
	data: TData[];
	onChange: (value: string) => void;
	disabled?: boolean;
}

const GenericSelect = ({ label, value, data, onChange, disabled }: IGenericSelect) => {
	return (
		<FormControl fullWidth>
			<InputLabel>{label}</InputLabel>
			<Select
				size="small"
				value={value}
				onChange={(e: any, value) => {
					onChange(e.target.value);
				}}
				disabled={disabled}
			>
				{data?.map((item: TData, index: number) => {
					return (
						<MenuItem value={item.key} key={`menu_item_${label}${index}`}>{item.value}</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

export default GenericSelect;