import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "next-i18next";

import GenericDialog from "./Dialog";

interface IGenericPrompt {
    open: boolean;
    setOpen: (state: boolean) => void;
    title: string;
    text?: string;
    agreeTitle?: string;
    cancelTitle?: string;
    agreeAction: () => void;
    cancelAction?: () => void;
}

const GenericPrompt = ({
    open,
    setOpen,
    title,
    text,
    agreeTitle,
    cancelTitle,
    agreeAction,
    cancelAction,
}: IGenericPrompt) => {
    const { t } = useTranslation("common");

    return (
        <GenericDialog open={open} setOpen={setOpen} disableClickOutside={true}>
            <DialogTitle>{title}</DialogTitle>
            {text ? <DialogContent>{text}</DialogContent> : null}
            <DialogActions>
                <Button
                    onClick={() => {
                        if (cancelAction) {
                            cancelAction();
                        }
                        setOpen(false);
                    }}
                >
                    {cancelTitle || t("common:actions.cancel")}
                </Button>
                <Button
                    onClick={() => {
                        agreeAction();
                        setOpen(false);
                    }}
                    variant={"contained"}
                >
                    {agreeTitle || t("common:actions.confirm")}
                </Button>
            </DialogActions>
        </GenericDialog>
    );
};

export default GenericPrompt;
