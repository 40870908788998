import { IContact } from "./Contact";

export interface IVehicle {
	id?: number;
	vin: string;
	brand: IBrand;
	model: IModel;
	engine_id?: number;
	engine?: IEngine;
	license_plate?: string;
	condition?: string;
	colour?: string;
	position?: string;
	photo_file_id?: string | null; //hlavní foto auta
	photo_file_download_token?: string | null;
	owner?: IContact; //ID of contact
	owner_id?: number;
	type?: string;
	first_registration?: string; //date
	fuel_type?: 'petrol' | 'diesel' | 'electric' | 'lpg' | 'hybrid' | 'hydrogen' | 'other';
	transmission?: 'manual' | 'automatic';
	power_output?: number; //in kw
	number_of_seats?: number;
	warranty_end_at?: string; //date
	mileage?: number;
	number_of_keys?: number; //number of keys
	range?: number;
	country_of_origin?: string; //country
	technical_inspection?: string; //date
	next_service?: string; //date
	last_service?: string; //date ??
	service_comment?: string;
}

export interface IBrand {
	id?: number;
	name: string;
	models?: IModel[];
}

export interface IModel {
	id?: number;
	brand_id: number;
	name: string;
	engines?: IEngine[];
}

export interface IEngine {
	id?: number;
	model_id: number;
	name: string;
	capacity?: number;
	fuel_type: IVehicle["fuel_type"];
	power: IVehicle["power_output"];
}

export const ACTION = {
	SET_FIELD: "set_field",
	RESET_FORM: "reset_form"
};