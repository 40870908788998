import {
    Brush,
    CameraAlt,
    DirectionsCar,
    Download,
    Edit,
    Expand,
    FindInPage,
    Lock,
    LockOpen,
    Person,
    PictureAsPdf,
    QrCode,
    Refresh,
    Replay,
    Save,
    Send,
    SportsScore,
    Stop,
    Undo,
} from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import GestureIcon from "@mui/icons-material/Gesture";
import {
    Button,
    Collapse,
    IconButton,
    List,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";

import styles from "@/styles/Generic/Buttons/GenericButton.module.scss";

import GenericPrompt from "../Prompt";

export const GenericButtonHolder = ({
    noGutters,
    children,
    style,
}: {
    noGutters?: boolean;
    children: any;
    style?: any;
}) => {
    return (
        <div
            className={`${styles.buttons_holder} ${
                noGutters ? styles.no_gutters : ``
            }`}
            style={style}
        >
            <Stack direction={`row`} spacing={1} alignItems={`center`}>
                {children}
            </Stack>
        </div>
    );
};

const GenericButton = ({
    ref,
    title,
    tooltipTitle,
    customIcon,
    type,
    onClick,
    className,
    small,
    style,
    disabled,
    fullWidth,
    askBeforeAction,
    actionDescription,
    hasMenu,
    menuItems,
}: {
    ref?: any;
    title?: string;
    tooltipTitle?: string;
    customIcon?: any;
    type?:
        | "add"
        | "edit"
        | "save"
        | "done"
        | "normal"
        | "discard"
        | "accept"
        | "remove"
        | "download"
        | "pdf"
        | "list"
        | "signature"
        | "draw"
        | "undo"
        | "close"
        | "lock"
        | "unlock"
        | "reload"
        | "send"
        | "detail"
        | "start"
        | "expand"
        | "collapse"
        | "car"
        | "contact"
        | "stop"
        | "qrCode"
        | "retry"
        | "camera"
        | undefined;
    onClick?: (e: any) => void;
    className?: string;
    small?: boolean;
    style?: any;
    disabled?: boolean;
    fullWidth?: boolean;
    askBeforeAction?: boolean;
    actionDescription?: string;
    hasMenu?: boolean;
    menuItems?: any[];
}) => {
    const [promptOpen, setPromptOpen] = useState(false);
    const buttonRef = useRef(null);
    const { t } = useTranslation(["common"]);
    const icon = customIcon ? (
        customIcon
    ) : type === "add" ? (
        <Add />
    ) : type === "save" ? (
        <Save />
    ) : type === "edit" ? (
        <Edit />
    ) : type === "discard" ? (
        <CloseIcon />
    ) : type === "close" ? (
        <CloseIcon />
    ) : type === "accept" || type === "done" ? (
        <CheckIcon />
    ) : type === "download" ? (
        <Download />
    ) : type === "pdf" ? (
        <PictureAsPdf />
    ) : type === "remove" ? (
        <DeleteIcon />
    ) : type === "list" ? (
        <List />
    ) : type === "signature" ? (
        <GestureIcon />
    ) : type === "draw" ? (
        <Brush />
    ) : type === "undo" ? (
        <Undo />
    ) : type === "lock" ? (
        <Lock />
    ) : type === "unlock" ? (
        <LockOpen />
    ) : type === "reload" ? (
        <Refresh />
    ) : type === "send" ? (
        <Send />
    ) : type === "detail" ? (
        <FindInPage />
    ) : type === "start" ? (
        <SportsScore />
    ) : type === "expand" ? (
        <Expand />
    ) : type === "collapse" ? (
        <Collapse />
    ) : type === "car" ? (
        <DirectionsCar />
    ) : type === "contact" ? (
        <Person />
    ) : type === "stop" ? (
        <Stop />
    ) : type === "qrCode" ? (
        <QrCode />
    ) : type === "retry" ? (
        <Replay />
    ) : type === "camera" ? (
        <CameraAlt />
    ) : (
        <ArrowForwardIosIcon />
    );

    const colorClass =
        type == "add"
            ? styles.add_button
            : type == "save"
            ? styles.save_button
            : type == "done"
            ? styles.save_button
            : type == "accept"
            ? styles.accept_button
            : type == "remove"
            ? styles.remove_button
            : type == "discard"
            ? styles.remove_button
            : "";

    const action = (e: any) => {
        if (onClick) {
            if (askBeforeAction) {
                setPromptOpen(true);
                buttonRef.current = e;
            } else {
                onClick(e);
            }
        }
    };

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    return (
        <div style={style}>
            <Tooltip title={tooltipTitle || ""} arrow>
                <div>
                    {title ? (
                        <Button
                            fullWidth={fullWidth}
                            disabled={disabled}
                            ref={ref}
                            startIcon={icon}
                            onClick={
                                hasMenu
                                    ? (e: any) => {
                                          setMenuAnchor(e.currentTarget);
                                      }
                                    : action
                            }
                            className={`${styles.button} ${
                                styles.button_holder
                            } ${
                                small ? styles.small : ""
                            } ${colorClass} ${className}`}
                        >
                            {" "}
                            {title}
                        </Button>
                    ) : (
                        <IconButton
                            disabled={disabled}
                            ref={ref}
                            onClick={
                                hasMenu
                                    ? (e: any) => {
                                          setMenuAnchor(e.currentTarget);
                                      }
                                    : action
                            }
                            className={`${styles.button} ${styles.add_button} ${className} ${colorClass}`}
                            size={small ? `small` : undefined}
                        >
                            {icon}
                        </IconButton>
                    )}
                </div>
            </Tooltip>
            <GenericPrompt
                open={promptOpen}
                setOpen={setPromptOpen}
                title={t("common:message.sure")}
                text={actionDescription || ""}
                agreeAction={() => {
                    if (onClick) {
                        onClick(buttonRef.current);
                    }
                }}
            />
            {hasMenu ? (
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={() => {
                        setMenuAnchor(null);
                    }}
                >
                    {menuItems
                        ? menuItems?.map((item: any, index: number) => {
                              return (
                                  <MenuItem
                                      key={`item${index}`}
                                      onClick={() => {
                                          item.onClick();
                                          setMenuAnchor(null);
                                      }}
                                  >
                                      {item.title}
                                  </MenuItem>
                              );
                          })
                        : null}
                </Menu>
            ) : null}
        </div>
    );
};

export default GenericButton;
