import { Stack } from "@mui/system";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import { IBrand } from "@/types/Vehicle";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDialog from "../Generic/Dialog";
import GenericTextField from "../Generic/TextField";

const AddBrand = ({
    open,
    setOpen,
    onSave,
}: {
    open: boolean;
    setOpen: (state: boolean) => void;
    onSave: (data: IBrand) => void;
}) => {
    const { t } = useTranslation(["common", "vehicles"]);
    const [name, setName] = useState("");

    const saveData = () => {
        fetch(`/api/vehicles/brands`, {
            method: "POST",
            body: JSON.stringify({
                name: name,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setName("");
                setOpen(false);
                onSave(data?.response);
            });
    };

    return (
        <GenericDialog
            open={open}
            setOpen={setOpen}
            title={t("common:actions.addBrand")}
            showCloseButton
        >
            <Stack direction={`row`} spacing={2}>
                <GenericTextField
                    label={t("vehicles:brandName")}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    fullWidth
                />
                <GenericButton
                    type="save"
                    title={t("common:actions.save")}
                    onClick={saveData}
                />
            </Stack>
        </GenericDialog>
    );
};

export default AddBrand;
