import { ExpandMore, Send, WarningRounded } from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import {
    Avatar,
    AvatarGroup,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import {
    API_DATE_TIME_FORMAT,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
} from "@/defs/global/global";
import styles from "@/styles/Issues/IssueItem.module.scss";
import { IIssue, IIssueComment, IIssueTask } from "@/types/Issue";
import { IUser } from "@/types/User";
import { useAuth } from "@/utils/AuthProvider";
import { useNotifications } from "@/utils/NotificationsProvider";
import { getNameInitials, stripHtml } from "@/utils/String";
import { makeSwrRequest } from "@/utils/SwrProvider";
import { useUsers } from "@/utils/UsersProvider";

import FilesButton from "../FileManager/ShowFilesButton";
import GenericButton, {
    GenericButtonHolder,
} from "../Generic/Buttons/GenericButton";
// import { issueStatuses } from '@/defs/Issues/Issue';
import GenericProgress from "../Generic/Progress";
import AddIssue from "./AddIssue";

interface IIssueItem {
    issue: IIssue;
    reloadIssues?: any;
    expanded?: boolean;
    onChange?: () => void;
}

const IssueItem = ({ issue, reloadIssues, expanded, onChange }: IIssueItem) => {
    const { sendBulkNotifications } = useNotifications();
    const { t } = useTranslation(["common", "issues"]);
    const [openDetail, setOpenDetail] = useState(expanded ? expanded : false);
    const [comment, setComment] = useState("");

    const { data: comments, mutate } = makeSwrRequest(
        openDetail ? `/api/issues/comments?issue_id=${issue?.id}` : null,
        `GET`,
        {
            refreshInterval: 15000,
        }
    );

    const { userId, userData } = useAuth();

    const mainNotificationRecipients = [
        issue?.author?.id,
        issue?.maintainer?.id,
    ];

    const refreshData = () => {
        reloadIssues();
        if (onChange) {
            onChange();
        }
    };

    const sendComment = () => {
        fetch("/api/issues/comments", {
            method: "POST",
            body: JSON.stringify({
                issue_id: issue?.id,
                user_id: parseInt(userId),
                content: comment,
            }),
        })
            .then((res) => res.json())
            .then((data: any) => {
                setComment("");
                sendBulkNotifications(
                    mainNotificationRecipients,
                    t("issues:commentAdded"),
                    comment,
                    {
                        issue_id: issue?.id,
                    }
                );
                if (reloadIssues) {
                    refreshData();
                }
                mutate();
            });
    };

    const acceptAssignment = () => {
        setLoading(true);
        fetch(`/api/issues/${issue?.id}`, {
            method: "PUT",
            body: JSON.stringify({
                executor_id: parseInt(`${userId}`),
                executor_accepted_at: dayjs().format(API_DATE_TIME_FORMAT),
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data_res) => {
                setLoading(false);

                sendBulkNotifications(
                    mainNotificationRecipients,
                    t("issues:caseAccepted"),
                    comment,
                    {
                        issue_id: issue?.id,
                    }
                );
                if (reloadIssues) {
                    refreshData();
                }
            });
    };

    const changeIssueStatus = (status: IIssue["status"]) => {
        setLoading(true);
        fetch(`/api/issues/${issue?.id}`, {
            method: "PUT",
            body: JSON.stringify({
                status: status,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data_res) => {
                setLoading(false);

                const notificationRecipients = [...mainNotificationRecipients];

                issue?.assignees?.forEach((assignee: IUser) => {
                    notificationRecipients.push(assignee?.id);
                });

                sendBulkNotifications(
                    notificationRecipients,
                    t("issues:caseAccepted"),
                    "",
                    {
                        issue_id: issue?.id,
                    }
                );

                if (reloadIssues) {
                    refreshData();
                }
            });
    };

    const assignees = issue?.assignees?.map((assignee: any) => {
        return {
            id: assignee?.id,
            name: `${assignee.first_name} ${assignee.last_name}`,
        };
    });

    const issueTypes = [
        {
            key: "task",
            title: t("issues:issueTypes:task"),
            color: "var(--task_color)",
            icon: <AssignmentIcon />,
        },
        {
            key: "meeting",
            title: t("issues:issueTypes:meeting"),
            color: "var(--meeting_color)",
            icon: <GroupsIcon />,
        },
        {
            key: "phone-call",
            title: t("issues:issueTypes:call"),
            color: "var(--phoneCall_color)",
            icon: <LocalPhoneIcon />,
        },
        {
            key: "email",
            title: t("issues:issueTypes:email"),
            color: "var(--mail_color)",
            icon: <AlternateEmailIcon />,
        },
        {
            key: "lead",
            title: t("issues:issueTypes:lead"),
            color: "var(--lead_color)",
            icon: <AttachMoneyIcon />,
        },
    ];

    const issueType = issueTypes.find((item: any) => item.key == issue?.type);

    const [loading, setLoading] = useState(false);

    const hasDeadline =
        issue?.datetime_to &&
        issue?.type === "task" &&
        issue?.status === "in-progress";
    const daysTillDeadline = Math.round(
        hasDeadline ? dayjs(issue?.datetime_to).diff(dayjs(), "day", true) : 0
    );
    const hoursTillDeadline = Math.round(
        hasDeadline ? dayjs(issue?.datetime_to).diff(dayjs(), "hour", true) : 0
    );

    const closeToDeadline = hoursTillDeadline < 30;

    const issueStatuses = [
        {
            key: "in-progress",
            title: t("issues:issueStatus:progress"),
        },
        {
            key: "done",
            title: t("issues:issueTypes:done"),
        },
        {
            key: "canceled",
            title: t("issues:issueTypes:canceled"),
        },
    ];

    const [openIssueEdit, setOpenIssueEdit] = useState(false);

    return (
        <Accordion
            expanded={openDetail}
            onChange={() => {
                setOpenDetail(!openDetail);
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <div
                    className={`${styles.issue_item_holder}`}
                    onClick={() => {
                        setOpenDetail(!openDetail);
                    }}
                >
                    {loading ? (
                        <div className={styles.loading_overlay}>
                            <GenericProgress />
                        </div>
                    ) : null}
                    <div className={styles.icon_holder}>
                        <Tooltip title={issueType?.title || ""} arrow>
                            <Avatar
                                sx={{
                                    bgcolor:
                                        issueType?.color ||
                                        `var(--secondary_color)`,
                                    // width: '27px',
                                    // height: '27px',
                                    // fontSize: '8px'
                                    scale: "0.7",
                                    transformOrigin: "left center",
                                }}
                            >
                                {issueType?.icon || ``}
                            </Avatar>
                        </Tooltip>
                    </div>
                    <div className={styles.content_holder}>
                        <p className={styles.title}>{issue?.subject}</p>
                        <p
                            className={`${styles.content}`}
                            dangerouslySetInnerHTML={{
                                __html: openDetail
                                    ? issue?.content
                                    : `${stripHtml(issue?.content).slice(
                                          0,
                                          100
                                      )}...`,
                            }}
                        />
                        {assignees && assignees?.length > 0 ? (
                            <span className={`${styles.smallText}`}>
                                <Stack
                                    direction={`row`}
                                    alignItems={`center`}
                                    spacing={1}
                                >
                                    <span className={`boldText`}>
                                        {t("issues:assignee")}:
                                    </span>
                                    <AvatarGroup>
                                        {assignees?.map(
                                            (item: any, index: number) => {
                                                const isExecutor =
                                                    item?.id ===
                                                    issue?.executor?.id;
                                                return (
                                                    <Tooltip
                                                        title={`${item.name}${
                                                            isExecutor
                                                                ? ` (${t(
                                                                      "common:job.executor"
                                                                  )})`
                                                                : ``
                                                        }`}
                                                        key={`assigneeItem${issue?.id}${index}`}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                width: 20,
                                                                height: 20,
                                                                fontSize:
                                                                    "10px",
                                                                bgcolor:
                                                                    isExecutor
                                                                        ? `var(--secondary_color)`
                                                                        : ``,
                                                            }}
                                                        >
                                                            {getNameInitials(
                                                                item.name
                                                            )}
                                                        </Avatar>
                                                    </Tooltip>
                                                );
                                            }
                                        )}
                                    </AvatarGroup>
                                </Stack>
                            </span>
                        ) : null}
                        {hasDeadline && (
                            <p
                                className={`${styles.smallText} ${
                                    closeToDeadline ? styles.warning : ``
                                }`}
                            >
                                <span className={`boldText`}>
                                    {t("common:job.deadline")}:
                                </span>{" "}
                                {daysTillDeadline < 0 ? (
                                    <>
                                        <WarningRounded />{" "}
                                        {t("common:job.overdue")}
                                    </>
                                ) : daysTillDeadline > 1 ? (
                                    ` ${t(
                                        "issues:due.in"
                                    )} ${daysTillDeadline} ${t(
                                        "issues:due.days"
                                    )}`
                                ) : hoursTillDeadline > 10 ? (
                                    `${t(
                                        "issues:due.in"
                                    )} ${hoursTillDeadline} ${t(
                                        "issues:due.hours"
                                    )}`
                                ) : (
                                    `${t(
                                        "issues:due.today"
                                    )} - ${hoursTillDeadline} ${t(
                                        "issues:due.hoursLeft"
                                    )}`
                                )}{" "}
                                (
                                {dayjs(issue?.datetime_to).format(
                                    DATE_TIME_FORMAT
                                )}
                                )
                            </p>
                        )}
                        {issue?.assignees?.some((assignee) => {
                            return assignee.id == userId;
                        }) &&
                        issue?.executor == null &&
                        issue?.status !== "cancelled" ? (
                            <GenericButton
                                title={t("issues:acceptAssigment")}
                                type={`accept`}
                                small
                                onClick={(e) => {
                                    e.stopPropagation();
                                    acceptAssignment();
                                }}
                                className={styles.issue_action_button}
                            />
                        ) : issue?.executor_accepted_at !== null ? (
                            <>
                                <p className={`${styles.smallText}`}>
                                    <span className={`boldText`}>
                                        {t("issues:accepted")}
                                    </span>{" "}
                                    {t("issues.at")}{" "}
                                    {dayjs(issue?.executor_accepted_at).format(
                                        DATE_FORMAT
                                    )}{" "}
                                    {t("issues:by")}{" "}
                                    {issue?.executor?.first_name}{" "}
                                    {issue?.executor?.last_name}
                                </p>
                                {issue?.executor?.id === userId ? (
                                    <Stack
                                        direction={`row`}
                                        alignItems={`center`}
                                    >
                                        {issue?.status === "in-progress" ? (
                                            <GenericButton
                                                type="done"
                                                title={t("issues:markDone")}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    changeIssueStatus(`done`);
                                                }}
                                                small
                                                className={
                                                    styles.issue_action_button
                                                }
                                            />
                                        ) : issue?.status === "done" ? (
                                            <span
                                                className={`${styles.smallText} boldText successText`}
                                            >
                                                {t("common:state.finished")}{" "}
                                                {dayjs(
                                                    issue?.updated_at
                                                ).format(DATE_TIME_FORMAT)}
                                            </span>
                                        ) : issue?.status === "cancelled" ? (
                                            <span
                                                className={`${styles.smallText} boldText cancelledText`}
                                            >
                                                {t("common:state.cancelledAt")}{" "}
                                                {dayjs(
                                                    issue?.updated_at
                                                ).format(DATE_TIME_FORMAT)}
                                            </span>
                                        ) : null}
                                    </Stack>
                                ) : null}
                            </>
                        ) : (issue?.status !== "done" ||
                              issue?.status !== "cancelled") &&
                          issue?.assignees?.length === 0 &&
                          issue?.author?.id === userId ? (
                            <GenericButton
                                type="done"
                                title={t("issues:markDone")}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeIssueStatus(`done`);
                                }}
                                small
                                className={styles.issue_action_button}
                            />
                        ) : null}
                        <div className={styles.basic_info_holder}>
                            <span className={styles.author}>
                                {issue?.author?.first_name}{" "}
                                {issue?.author?.last_name}
                            </span>
                            ,{" "}
                            <span className={styles.created_at}>
                                {dayjs(issue?.created_at).format(
                                    DATE_TIME_FORMAT
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.detail_holder}>
                    {issue?.id ? (
                        <IssueSubTasks issue={issue} onAdd={() => {}} />
                    ) : null}
                    <table className={`table ${styles.detail_info_table}`}>
                        <tbody>
                            <tr>
                                <th>{t("issues:issueType")}</th>
                                <td>{issue?.type}</td>
                            </tr>
                            <tr>
                                <th>{t("issues:author")}</th>
                                <td>
                                    {issue?.author?.first_name}{" "}
                                    {issue?.author?.last_name}
                                </td>
                            </tr>
                            <tr>
                                <th>{t("issues:maintainer")}</th>
                                <td>
                                    {issue?.maintainer
                                        ? `${issue?.maintainer?.first_name} ${issue?.maintainer?.last_name}`
                                        : `-`}
                                </td>
                            </tr>
                            {issue?.assignees && (
                                <>
                                    <tr>
                                        <th>{t("issues:assignee")}</th>
                                        <td>
                                            {issue?.assignees
                                                ?.map(
                                                    (assignee) =>
                                                        `${assignee?.first_name} ${assignee?.last_name}`
                                                )
                                                .join(`, `)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("common:job.executor")}</th>
                                        <td>
                                            {issue?.executor
                                                ? `${issue?.executor?.first_name} ${issue?.executor?.last_name}`
                                                : `-`}
                                        </td>
                                    </tr>
                                </>
                            )}
                            {hasDeadline && (
                                <>
                                    <tr>
                                        <th>{t("common:job.deadline")}</th>
                                        <td>
                                            {dayjs(issue?.datetime_to).format(
                                                DATE_TIME_FORMAT
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("issues:daysDeadline")}</th>
                                        <td>{daysTillDeadline}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("issues:hoursDeadline")}</th>
                                        <td>{hoursTillDeadline}</td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <th>{t("common:info.status")}</th>
                                <td>
                                    <span
                                        className={`boldText ${
                                            issue?.status === "done"
                                                ? `successText`
                                                : issue?.status === "canceled"
                                                ? `cancelledText`
                                                : ``
                                        }`}
                                    >
                                        {
                                            issueStatuses.find(
                                                (item: any) =>
                                                    item.key === issue?.status
                                            )?.title
                                        }
                                    </span>
                                    {issue?.status === "done" ||
                                    issue?.status === "cancelled"
                                        ? ` ${t("issues:at")} ${dayjs(
                                              issue?.updated_at
                                          ).format(DATE_TIME_FORMAT)}`
                                        : null}
                                </td>
                            </tr>
                            {/* <tr>
								<th>Connected to</th>
								<td>{JSON.stringify(issue?.connections)}</td>
							</tr> */}
                        </tbody>
                    </table>
                    {expanded ? (
                        <FilesButton type={`issue`} recordId={issue?.id} />
                    ) : null}
                    <div className={styles.comments_holder}>
                        <h4>{t("issues:comments")}</h4>
                        <div className={styles.comment_builder}>
                            <TextField
                                label={t("issues:addComment")}
                                fullWidth
                                size="small"
                                value={comment}
                                onChange={(e: any) => {
                                    setComment(e.target.value);
                                }}
                                onKeyUp={(e: any) => {
                                    if (e.keyCode == 13) {
                                        sendComment();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                color="secondary"
                                                disabled={comment == ""}
                                                onClick={() => {
                                                    sendComment();
                                                }}
                                            >
                                                <Send fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={styles.comments_list}>
                            {comments && comments.length > 0
                                ? comments.map(
                                      (
                                          comment: IIssueComment,
                                          index: number
                                      ) => {
                                          return (
                                              <IssueCommentItem
                                                  comment={comment}
                                                  key={`issue${issue?.id}_comment${index}`}
                                              />
                                          );
                                      }
                                  )
                                : "no-comments"}
                        </div>
                    </div>
                    <div>
                        <GenericButtonHolder>
                            {userData?.role === "admin" ||
                                (issue?.author?.id === userData?.id && (
                                    <>
                                        <GenericButton
                                            type={`edit`}
                                            small
                                            title={t("issues:editIssue")}
                                            onClick={() => {
                                                setOpenIssueEdit(true);
                                            }}
                                            className={
                                                styles.issue_action_button
                                            }
                                        />
                                        <AddIssue
                                            data={issue}
                                            open={openIssueEdit}
                                            setOpen={setOpenIssueEdit}
                                            onAdd={() => {
                                                reloadIssues();
                                            }}
                                        />
                                    </>
                                ))}
                            {userData?.role === "admin" &&
                                issue?.status === "in-progress" && (
                                    <GenericButton
                                        type="discard"
                                        title={t("issues:cancelCase")}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeIssueStatus(`cancelled`);
                                        }}
                                        small
                                        className={styles.issue_action_button}
                                        askBeforeAction={true}
                                    />
                                )}
                        </GenericButtonHolder>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const IssueCommentItem = ({ comment }: { comment: IIssueComment }) => {
    const { t } = useTranslation("issues");

    const { users } = useUsers();
    const user = users?.find((user: IUser) => {
        return user.id == comment.user_id;
    });

    const userFullNameExists = user?.first_name && user?.last_name;
    const userInitials = userFullNameExists
        ? `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`
        : ``;

    return (
        <div className={styles.comment_item}>
            <Avatar className={styles.avatar}>
                {userInitials || <PersonIcon />}
            </Avatar>
            <div className={styles.content_holder}>
                <p className={styles.text}>{comment.content}</p>
                <p className={styles.author}>
                    <span className={styles.author_name}>
                        {userFullNameExists
                            ? `${user.first_name} ${user.last_name}`
                            : `${t("issues:user")} ${comment.user_id}`}{" "}
                        &bull;
                    </span>{" "}
                    <span className={styles.date}>
                        {dayjs(comment.created_at).format(DATE_TIME_FORMAT)}
                    </span>
                </p>
            </div>
        </div>
    );
};

const IssueSubTasks = ({
    issue,
    onAdd,
}: {
    issue: IIssue;
    onAdd: () => void;
}) => {
    const {
        data: issueTasks,
        mutate: refreshIssueTasks,
        isValidating: loadingIssueTasks,
    } = makeSwrRequest(`/api/issues/tasks/?issueId=${issue?.id}`);

    const { t } = useTranslation(["common", "issues"]);

    const [newTaskValue, setNewTaskValue] = useState("");

    const addNewTask = () => {
        const executorId = issue?.executor
            ? issue?.executor?.id
            : issue?.assignees
            ? issue?.assignees[0]?.id
            : issue?.maintainer?.id || issue?.author?.id || 1;
        fetch(`/api/issues/tasks`, {
            method: "POST",
            body: JSON.stringify({
                issue_id: issue?.id,
                executor_id: executorId,
                content: newTaskValue,
                status: "in-progress",
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setNewTaskValue("");
                refreshIssueTasks();
            });
    };

    return (
        <div className={styles.subTasks_holder}>
            <h4>{t("issues:subTasks")}</h4>
            <div className={styles.subTasks_list_holder}>
                {issueTasks?.map((task: IIssueTask) => {
                    return (
                        <SubTaskItem
                            key={task.id}
                            task={task}
                            refreshIssueTasks={refreshIssueTasks}
                        />
                    );
                })}
            </div>
            <div className={styles.addNewTask_holder}>
                <TextField
                    label={t("issues:addSubTask")}
                    fullWidth
                    size="small"
                    value={newTaskValue}
                    onChange={(e: any) => {
                        setNewTaskValue(e.target.value);
                    }}
                    variant={`standard`}
                    onKeyUp={(e: any) => {
                        if (e.keyCode == 13) {
                            addNewTask();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color="secondary"
                                    disabled={newTaskValue == ""}
                                    onClick={() => {
                                        addNewTask();
                                    }}
                                >
                                    <Send fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

const SubTaskItem = ({
    task,
    refreshIssueTasks,
}: {
    task: IIssueTask;
    refreshIssueTasks: () => void;
}) => {
    const [inProgress, setInProgress] = useState(false);
    const setDone = (taskId: number) => {
        setInProgress(true);
        fetch(`/api/issues/tasks/${taskId}`, {
            method: "PUT",
            body: JSON.stringify({
                status: "done",
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                refreshIssueTasks();
                setInProgress(false);
            })
            .catch((e) => {
                setInProgress(false);
            });
    };

    return (
        <div
            className={`${styles.subTask_item} ${
                task?.status === "done" ? styles.done : ``
            }`}
        >
            {!inProgress ? (
                <div
                    className={`${styles.done_button}`}
                    onClick={() => {
                        if (task?.status !== "done") setDone(task?.id);
                    }}
                >
                    <DoneRoundedIcon className={styles.icon} />
                </div>
            ) : (
                <GenericProgress size={`tiny`} />
            )}
            <p className={styles.title}>{task?.content}</p>
        </div>
    );
};

export default IssueItem;
