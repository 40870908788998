import { useEffect, useMemo } from "react";
import { makeSwrRequest } from "./SwrProvider";
import { IBrand } from "@/types/Vehicle";
import { sortArrayBy } from "./arrays";

export const useBrands = (brandId?: string, modelId?: string) => {

	const { data: brands, mutate: refreshBrands, isValidating: loadingBrands } = makeSwrRequest(`/api/vehicles/brands`);
	const { data: models, mutate: refreshModels, isValidating: loadingModels } = makeSwrRequest(brandId ? `/api/vehicles/models?brandId=${brandId}` : null);
	const { data: engines, mutate: refreshEngines, isValidating: loadingEngines } = makeSwrRequest(modelId ? `/api/vehicles/engines?modelId=${modelId}` : null);

	const isLoading = loadingBrands || loadingModels || loadingEngines;

	const selectedBrand = brandId ? brands?.find((item: IBrand) => item.id === parseInt(brandId)) || null : null;
	const selectedModel = modelId ? models?.find((item: IBrand) => item.id === parseInt(modelId)) || null : null;

	useEffect(() => {
		refreshAll();
	}, [brandId, modelId]);

	const refreshAll = () => {
		return new Promise((resolve, reject) => {
			refreshBrands().then(() => {
				refreshModels().then(() => {
					refreshEngines().then(() => {
						resolve(true);
					});
				});
			});
		})
	}

	return {
		brands,
		models,
		engines,
		refreshAll,
		refreshBrands,
		refreshModels,
		refreshEngines,
		isLoading,
		loadingBrands,
		loadingModels,
		loadingEngines,
		selectedBrand,
		selectedModel
	}
}