import { IUser } from "./User";

export const ACTIONS = {
	SET_FIELD: "set_field",
	SET_ASSIGNEES: "set_assignees",
	SET_DEFAULT: "set_default"
}

export interface IIssueTask {
	id: number;
	executor: IUser;
	content: string;
	status: 'pending' | 'done';
	done_at: string;
}

export interface IIssue {
	id?: number;
	author_id: number;
	author?: IUser;
	maintainer_id: number;
	maintainer?: IUser;
	assignee_ids?: number[];
	assignees?: IUser[];
	executor_id: number;
	executor?: IUser;
	executor_accepted_at?: string;
	datetime_from?: string;
	datetime_to?: string;
	deadline: Date;
	status: 'in-progress' | 'done' | 'cancelled' | any;
	subject: string;
	content: string;
	connections?: {
		user_id?: string | number;
		vehicle_id?: string | number;
		job_id?: string | number;
		contact_id?: string | number;
		issue_id?: string | number;
	};
	category?: IIssueCategory;
	tasks?: IIssueTask[];
	created_at?: string;
	updated_at?: string;
	type?: 'task' | 'meeting' | 'phone-call' | 'email' | 'lead';
}

export interface IIssueComment {
	id?: number;
	issue_id: number;
	user_id?: number;
	content: string;
	created_at: string;
}

export interface IIssueCategory {
	id: string | number;
	name: string;
	created_at: string;
	updated_at: string;
}