import { useTranslation } from "react-i18next";

export interface IFuelType {
    key: string;
    value: string;
}

export const useFuelTypes = () => {
    const { t } = useTranslation("vehicles");

    const fuelTypes: IFuelType[] = [
        {
            key: "petrol",
            value: t("vehicles:fuelType.petrol"),
        },
        {
            key: "diesel",
            value: t("vehicles:fuelType.diesel"),
        },
        {
            key: "electric",
            value: t("vehicles:fuelType.electric"),
        },
        {
            key: "lpg",
            value: t("vehicles:fuelType.lpg"),
        },
        {
            key: "hybrid",
            value: t("vehicles:fuelType.hybrid"),
        },
        {
            key: "hydrogen",
            value: t("vehicles:fuelType.hydrogen"),
        },
        {
            key: "other",
            value: t("vehicles:fuelType.other"),
        },
    ];

    return fuelTypes;
};
