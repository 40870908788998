import { TConnections } from '@/types/FileManager';
import { tusUploadEndpointUrl } from './Uploads/Uploads';

import Uppy from '@uppy/core'
import Tus from '@uppy/tus'

export const getUppyInstance = (autoProceed: boolean = true) => {
	return new Uppy({
		meta: { type: 'avatar' },
		restrictions: {
			maxNumberOfFiles: 100,
			maxFileSize: 100000000000,
		},
		autoProceed: autoProceed === false ? false : true,
	});
}

export const setUppyProps = (uppy: Uppy, uploadToken: string) => {
	if (uploadToken) {
		uppy.use(Tus, {
			endpoint: tusUploadEndpointUrl,
			retryDelays: [0, 1000, 3000, 5000],
			headers: {
				'tus-auth-token': uploadToken
			},
		});
	}

	return uppy;
}

export const generateUploadAuthToken = (): Promise<string> => {
	return new Promise((resolve, reject) => {
		fetch('/api/uploads/tusAuthToken', {
			method: 'POST',
			body: JSON.stringify({
				type: 'upload'
			})
		}).then(res => res.json()).then((data: any) => {
			resolve(data?.token || null);
		}).catch((e) => {
			reject(e);
		});
	});
}

export const saveFile = (uploadId: string, uploadDirectoryId: string | null, connections: TConnections, fileName: string) => {
	return new Promise((resolve, reject) => {
		const connection = connections ? {
			[`${connections.type}_id`]: parseInt(`${connections.recordId}`)
		} : null;

		fetch('/api/files/create', {
			method: 'POST',
			body: JSON.stringify({
				type: 'file',
				parentId: uploadDirectoryId,
				name: fileName,
				uploadId: uploadId,
				connections: connection
			})
		}).then(res => res.json()).then((dataRes: any) => {
			resolve(dataRes);
		}).catch((e) => {
			reject(e);
		})
	})
}

export const uploadCompleted = (uppyResult: any) => {
	const url = uppyResult?.successful[0]?.uploadURL;
	const uploadId = url?.replace(`${tusUploadEndpointUrl}/`, "");
	const fileName = uppyResult?.successful[0]?.name;

	return {
		url,
		uploadId,
		fileName
	}
}