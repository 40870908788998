import { Close } from "@mui/icons-material";
import { Drawer, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import styles from "@/styles/Generic/Drawer.module.scss";

import GenericPrompt from "./Prompt";

interface IGenericDrawer {
    open: boolean;
    setOpen: (state: boolean) => void;
    title?: string;
    titleButton?: any;
    children: any;
    askBeforeClose?: boolean;
    anchor?: "right" | "bottom";
    fullScreen?: boolean;
    showCloseButton?: boolean;
}

const GenericDrawer = ({
    open,
    setOpen,
    title,
    titleButton,
    children,
    askBeforeClose,
    anchor,
    fullScreen,
    showCloseButton,
}: IGenericDrawer) => {
    const { t } = useTranslation("common");

    const [promptOpen, setPromptOpen] = useState(false);

    const closeDrawer = () => {
        if (askBeforeClose) {
            setPromptOpen(true);
        } else {
            setOpen(false);
        }
    };

    const styleProps = !fullScreen
        ? {
              width: "100%",
              maxWidth: "700px",
          }
        : {
              width: "100%",
              height: "100%",
          };

    showCloseButton = showCloseButton === undefined ? true : showCloseButton;

    return (
        <>
            <Drawer
                open={open}
                onClose={() => {
                    closeDrawer();
                }}
                anchor={anchor || "right"}
                PaperProps={{
                    style: styleProps,
                }}
            >
                <div className={styles.drawer_holder}>
                    {showCloseButton && (
                        <IconButton
                            className={styles.close_button}
                            onClick={() => {
                                closeDrawer();
                            }}
                        >
                            <Close />
                        </IconButton>
                    )}
                    {title && (
                        <div className={styles.title_holder}>
                            <h2>{title}</h2>
                            {titleButton || null}
                        </div>
                    )}
                    {children}
                </div>
            </Drawer>
            <GenericPrompt
                open={promptOpen}
                setOpen={setPromptOpen}
                title={t("common:message.sure")}
                text={t("common:message.closeWindow")}
                agreeAction={() => setOpen(false)}
            />
        </>
    );
};

export default GenericDrawer;
