import { Autocomplete, Box, TextField } from "@mui/material";
import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";

import { countryList, CountryType } from "@/defs/Countries/CountryList";

interface ICountrySelect {
    value: string;
    onChange: (selectedValue: string) => void;
    className?: string;
}

const CountrySelect = ({ value, onChange, className }: ICountrySelect) => {
    const { t } = useTranslation("common");

    return (
        <Autocomplete
            options={countryList}
            className={className}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
                return (
                    <Box
                        component="li"
                        sx={{
                            "& > .flag_holder": {
                                mr: 2,
                                flexShrink: 0,
                                alignItems: "center",
                                display: "flex",
                            },
                        }}
                        {...props}
                    >
                        <div className={`flag_holder`}>
                            <Image
                                src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                                width={20}
                                height={20}
                                alt=""
                                objectFit="contain"
                                objectPosition={`center center`}
                            />
                        </div>
                        {/* <img
							loading="lazy"
							width="20"
							src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
							srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
							alt=""
						/> */}
                        {option.label} ({option.code})
                    </Box>
                );
            }}
            value={
                countryList.find((item: CountryType) => {
                    return item.code == value;
                }) || null
            }
            onChange={(event: any, newValue: CountryType | null) => {
                onChange(newValue?.code || "");
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={`small`}
                    label={t("common:actions:chooseCountry")}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};

export default CountrySelect;
