import { DateField, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { useTranslation } from "next-i18next";

import { DATE_FORMAT, DATE_TIME_FORMAT } from "@/defs/global/global";

interface IGenericDatePicker {
    label: string;
    inputFormat?: string;
    value: any;
    onChange: (value: Dayjs | null) => void;
    className?: string;
    onlyDate?: boolean;
    shouldValidate?: boolean;
    disabled?: boolean;
}

const GenericDatePicker = ({
    label,
    value,
    onChange,
    onlyDate,
    disabled,
}: IGenericDatePicker) => {
    const { t } = useTranslation("common");

    const checkError = (params: any) => {
        const inputValue = params?.inputProps?.value;
        return value === t("common:message.invalidDate") &&
            inputValue?.length > 0
            ? params.error
            : false;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {onlyDate ? (
                <DateField
                    label={label}
                    value={value}
                    onChange={onChange}
                    format={DATE_FORMAT}
                    fullWidth
                    size="small"
                    InputProps={{
                        required: false,
                        error: false,
                    }}
                    InputLabelProps={{
                        error: false,
                    }}
                    disabled={disabled}
                />
            ) : (
                <DateTimeField
                    label={label}
                    value={value}
                    onChange={onChange}
                    format={DATE_TIME_FORMAT}
                    InputProps={{
                        required: false,
                        error: false,
                    }}
                    InputLabelProps={{
                        error: false,
                    }}
                    fullWidth
                    size="small"
                    disabled={disabled}
                />
            )}
        </LocalizationProvider>
    );
};

export default GenericDatePicker;
