import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, Stack } from "@mui/material";
import { getContactName } from "helpers/Contacts";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useReducer, useState } from "react";

import { ACTIONS } from "@/defs/AddContact/AddCompany";
import styles from "@/styles/Contacts/AddContactDrawer.module.scss";
import { IPerson, IPhone } from "@/types/Contact";
import { checkForValidPhone } from "@/utils/Contacts";
import { useMessages } from "@/utils/MessagesProvider";
import { validateEmail } from "@/utils/String";
import { makeSwrRequest } from "@/utils/SwrProvider";

import GenericAutoComplete from "../Generic/AutoComplete";
import GenericButton from "../Generic/Buttons/GenericButton";
import CountrySelect from "../Generic/CountrySelect";
import GenericDrawer from "../Generic/Drawer";
import GenericPhoneInput from "../Generic/PhoneInput";
import GenericTextField from "../Generic/TextField";
import AddPerson from "./AddPerson";
import GenericSectionDivider from "./ContactSectionDivider";

interface IAddCompany {
    open: boolean;
    setOpen: (state: boolean) => void;
    quick?: boolean;
    onAdd?: (data: any) => void;
}

const AddCompany = ({ open, setOpen, onAdd }: IAddCompany) => {
    const { t } = useTranslation(["common", "contacts"]);

    const [simplified, setSimplified] = useState(false);
    const [openAddPerson, setOpenAddPerson] = useState(false);

    const companyReducer = (state: any, action: any) => {
        switch (action.type) {
            case ACTIONS.SET_FIELD:
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                };
            case ACTIONS.SET_COMPANY_INFORMATION:
                return {
                    ...state,
                    company: {
                        ...state.company,
                        [action.payload.key]: action.payload.value,
                    },
                };
            case ACTIONS.SET_CONTACT_PERSONS:
                return {
                    ...state,
                    ["company_contact_ids"]: [
                        ...(state.company_contact_ids || []),
                        action.payload,
                    ],
                };
            case ACTIONS.REMOVE_CONTACT_PERSON:
                return {
                    ...state,
                    company_contact_ids: state?.company_contact_ids?.filter(
                        (person: IPerson, index: number) => {
                            return (
                                index !== action.payload.contact_person_index
                            );
                        }
                    ),
                };
            case ACTIONS.SET_PHONES:
                return {
                    ...state,
                    phones: [
                        {
                            number: action.payload.number,
                            country_code: action.payload.country_code,
                        },
                    ],
                };
            case ACTIONS.ADD_EMPTY_SHIPPING_ADDRESS:
                if (!state.addresses) {
                    //if no array in state
                    state = { ...state, ["addresses"]: [] };
                }
                return { ...state, ["addresses"]: [...state.addresses, {}] };
            case ACTIONS.SET_BILLING_ADDRESS:
                return {
                    ...state,
                    billing_address: {
                        ...state.billing_address,
                        type: "billing",
                        [action.payload.key]: action.payload.value,
                    },
                };
            case ACTIONS.SET_SHIPPING_ADDRESS:
                return {
                    ...state,
                    ["addresses"]: state.addresses.map(
                        (address: any, index: number) => {
                            if (index === action.payload.address_index) {
                                return {
                                    ...address,
                                    type: "contact",
                                    [action.payload.key]: action.payload.value,
                                };
                            }
                            return address;
                        }
                    ),
                };
            case ACTIONS.REMOVE_SHIPPING_ADDRESS:
                return {
                    ...state,
                    addresses: state?.addresses?.filter(
                        (address: any, address_index: number) => {
                            return (
                                address_index !== action.payload.address_index
                            );
                        }
                    ),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(companyReducer, {
        type: "company",
        email: "",
        phones: [],
        addresses: null,
        company_contact_ids: null,
        billing_address: {
            type: "billing",
        },
    });

    const { data: contactPersons, mutate: refreshContactPersons } =
        makeSwrRequest(`/api/contacts`);

    const { showMessage } = useMessages();

    const validateCompanyForm = () => {
        if (!state?.email && !state?.phones[0]?.number) {
            showMessage("error", t("contacts:contactRequired"));
            return false;
        }
        if (
            (!state?.email && !state?.phones[0]?.country_code) ||
            state?.phones[0]?.country_code?.toString() === 0
        ) {
            showMessage("error", t("contacts:codeRequired"));
            return false;
        }
        return true;
    };

    const saveContact = async () => {
        const finalData = {
            ...state,
            company_contact_ids: state?.company_contact_ids
                ? state.company_contact_ids.map((item: any) => {
                      return item.id;
                  })
                : null,
            phones: checkForValidPhone(state?.phones)
                ? state?.phones?.map((phone: IPhone) => {
                      return phone?.number && phone?.country_code
                          ? {
                                number: phone?.number,
                                country_code: phone?.country_code,
                            }
                          : null;
                  })
                : [],
        };

        if (finalData.addresses) {
            finalData.addresses = [...finalData.addresses].concat(
                finalData.billing_address
            );
            delete finalData.billing_address;
        }

        if (!validateCompanyForm()) {
            return;
        }

        fetch("/api/contacts", {
            method: "POST",
            body: JSON.stringify(finalData),
        })
            .then((res: any) => {
                return res.json();
            })
            .then((data_res: any) => {
                showMessage(
                    "success",
                    t("contacts:contactID", { id: data_res?.response?.id })
                );
                setOpen(false);
                if (onAdd) {
                    onAdd(data_res?.response);
                }
            })
            .catch((e: any) => {
                showMessage("error", `${t("contacts:failed")} ${e.toString()}`);
            });
    };

    const emailIsValid = useMemo(() => {
        return validateEmail(state?.email);
    }, [state?.email]);

    const [isContactInfoEdited, setIsContectInfoEdited] = useState(false);

    useEffect(() => {
        setIsContectInfoEdited(true);
    }, [state?.email, state?.phone]);

    useEffect(() => {
        setIsContectInfoEdited(false);
    }, []);

    return (
        <>
            <GenericDrawer open={open} setOpen={setOpen}>
                <div className={styles.manage_contact_holder}>
                    {/* <FormGroup className={styles.simplify_switch_holder}>
						<FormControlLabel control={(
							<Switch
								checked={simplified}
								onChange={() => {
									setSimplified(!simplified)
								}}
							/>
						)} label={"Simplified form"} />
					</FormGroup> */}
                    <h2>{t("contacts:addComp")}</h2>
                    <div
                        className={`${styles.contact_section_holder} ${styles.basic_info}`}
                    >
                        <GenericSectionDivider
                            title={t("contacts:basicInfo")}
                        />
                        <ContactField
                            value={state?.company?.name}
                            simplified={simplified}
                            label={t("contacts:companyName")}
                            onChange={(e: any) => {
                                dispatch({
                                    type: ACTIONS.SET_COMPANY_INFORMATION,
                                    payload: {
                                        key: "name",
                                        value: e.target.value,
                                    },
                                });
                            }}
                            required
                        />
                        <ContactField
                            value={state?.company?.reg_number}
                            simplified={simplified}
                            label={t("contacts:companyID")}
                            onChange={(e: any) => {
                                dispatch({
                                    type: ACTIONS.SET_COMPANY_INFORMATION,
                                    payload: {
                                        key: "reg_number",
                                        value: e.target.value,
                                    },
                                });
                            }}
                        />
                        <ContactField
                            value={state?.company?.vat_number}
                            simplified={simplified}
                            label={t("contacts:tax")}
                            onChange={(e: any) => {
                                dispatch({
                                    type: ACTIONS.SET_COMPANY_INFORMATION,
                                    payload: {
                                        key: "vat_number",
                                        value: e.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div
                        className={`${styles.contact_section_holder} ${styles.contact_info}`}
                    >
                        <GenericSectionDivider
                            title={t("contacts:billingAdress")}
                        />
                        <div className={styles.billing_address_item}>
                            <div className={styles.inputs_holder}>
                                <ContactField
                                    value={state.billing_address.title}
                                    simplified={simplified}
                                    label={t("contacts:nameAdress")}
                                    onChange={(e: any) => {
                                        dispatch({
                                            type: ACTIONS.SET_BILLING_ADDRESS,
                                            payload: {
                                                key: "title",
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                <ContactField
                                    value={state.billing_address.street}
                                    simplified={simplified}
                                    label={t("common:form.street")}
                                    onChange={(e: any) => {
                                        dispatch({
                                            type: ACTIONS.SET_BILLING_ADDRESS,
                                            payload: {
                                                key: "street",
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                <ContactField
                                    value={state.billing_address.zip}
                                    simplified={simplified}
                                    label={t("common:form.zip")}
                                    onChange={(e: any) => {
                                        dispatch({
                                            type: ACTIONS.SET_BILLING_ADDRESS,
                                            payload: {
                                                key: "zip",
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                <ContactField
                                    value={state.billing_address.city}
                                    simplified={simplified}
                                    label={t("common:form.city")}
                                    onChange={(e: any) => {
                                        dispatch({
                                            type: ACTIONS.SET_BILLING_ADDRESS,
                                            payload: {
                                                key: "city",
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                />

                                <CountrySelect
                                    value={state.billing_address?.country}
                                    onChange={(value) => {
                                        dispatch({
                                            type: ACTIONS.SET_BILLING_ADDRESS,
                                            payload: {
                                                key: "country",
                                                value: value,
                                            },
                                        });
                                    }}
                                    className={styles.input_item}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles.contact_section_holder} ${styles.contact_info}`}
                    >
                        <GenericSectionDivider
                            title={t("contacts:contactInfo")}
                        />
                        <span
                            className={styles.input_item}
                            style={{
                                width: "100%",
                            }}
                        >
                            <GenericPhoneInput
                                countryCodeValue={
                                    state?.phones[0]?.country_code
                                }
                                phoneValue={state?.phones[0]?.number}
                                onChangeCountryCode={(e) => {
                                    dispatch({
                                        type: ACTIONS.SET_PHONES,
                                        payload: {
                                            country_code: e,
                                            number: state?.phones[0]?.number,
                                        },
                                    });
                                }}
                                onChangePhone={(e) => {
                                    dispatch({
                                        type: ACTIONS.SET_PHONES,
                                        payload: {
                                            country_code:
                                                state?.phones[0]?.country_code,
                                            number: e.target.value,
                                        },
                                    });
                                }}
                                required={
                                    !isContactInfoEdited
                                        ? false
                                        : state?.email?.length === 0
                                }
                                customValidator={() => {
                                    return !isContactInfoEdited
                                        ? true
                                        : state?.phones[0]?.number?.length >
                                              0 ||
                                              (state?.email?.length > 0 &&
                                                  emailIsValid);
                                }}
                            />
                        </span>
                        <ContactField
                            value={state?.email}
                            simplified={simplified}
                            label={t("common:form.email")}
                            onChange={(e: any) => {
                                dispatch({
                                    type: ACTIONS.SET_FIELD,
                                    payload: {
                                        key: "email",
                                        value: e.target.value,
                                    },
                                });
                            }}
                            required={
                                !isContactInfoEdited
                                    ? false
                                    : !state?.phones[0] ||
                                      state?.phones[0]?.number?.length === 0
                            }
                            customValidator={() => {
                                return !isContactInfoEdited
                                    ? true
                                    : state?.phones[0]?.number?.length > 0 ||
                                          (state?.email?.length > 0 &&
                                              emailIsValid);
                            }}
                            fullWidth
                        />
                    </div>
                    <div
                        className={`${styles.contact_section_holder} ${styles.bank_info}`}
                    >
                        <GenericSectionDivider
                            title={t("contacts:contactPerson")}
                        />
                        <Stack
                            direction={`row`}
                            spacing={2}
                            alignItems={`center`}
                        >
                            <GenericAutoComplete
                                options={contactPersons?.filter(
                                    (contact: IPerson) => {
                                        if (
                                            state?.company_contact_ids?.find(
                                                (el: IPerson) =>
                                                    el.id === contact.id
                                            )
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    }
                                )}
                                onChange={(event, newValue) => {
                                    dispatch({
                                        type: ACTIONS.SET_CONTACT_PERSONS,
                                        payload: newValue,
                                    });
                                }}
                                label={t("contacts:chooseContactPerson")}
                                multiple={false}
                                noOptionText={
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant={"outlined"}
                                        color={"success"}
                                        onClick={() => {
                                            setOpenAddPerson(true);
                                        }}
                                    >
                                        {t("contacts:createNew")}
                                    </Button>
                                }
                                getOptionLabel={(option) => {
                                    return `(${option.id}) ${getContactName(
                                        option
                                    )}`;
                                }}
                                renderOptionTitle={(option) => {
                                    return `${getContactName(option)}`;
                                }}
                            />
                            <GenericButton
                                type="add"
                                onClick={() => {
                                    setOpenAddPerson(true);
                                }}
                            />
                        </Stack>
                        <AddPerson
                            open={openAddPerson}
                            setOpen={setOpenAddPerson}
                            onAdd={(contact) => {
                                refreshContactPersons().then(() => {
                                    dispatch({
                                        type: ACTIONS.SET_CONTACT_PERSONS,
                                        payload: contact,
                                    });
                                });
                            }}
                        />
                        <table className={`table`}>
                            <tbody>
                                {state?.company_contact_ids?.map(
                                    (contact: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {getContactName(contact)}
                                                </td>
                                                <td>
                                                    <Button
                                                        endIcon={
                                                            <RemoveCircleIcon />
                                                        }
                                                        size={"small"}
                                                        onClick={() => {
                                                            dispatch({
                                                                type: ACTIONS.REMOVE_CONTACT_PERSON,
                                                                payload: {
                                                                    contact_person_index:
                                                                        index,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        {t("contacts:remove")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div
                        className={`${styles.contact_section_holder} ${styles.bank_info}`}
                    >
                        <GenericSectionDivider
                            title={t("contacts:shippingAdress")}
                        />
                        {state?.addresses?.map(
                            (address: any, address_index: number) => {
                                return (
                                    <div
                                        className={styles.shipping_address_item}
                                        key={`shippingAddress${address_index}`}
                                    >
                                        <div className={styles.inputs_holder}>
                                            <ContactField
                                                value={address.title}
                                                simplified={simplified}
                                                label={t("contacts:nameAdress")}
                                                onChange={(e: any) => {
                                                    dispatch({
                                                        type: ACTIONS.SET_SHIPPING_ADDRESS,
                                                        payload: {
                                                            address_index:
                                                                address_index,
                                                            key: "title",
                                                            value: e.target
                                                                .value,
                                                        },
                                                    });
                                                }}
                                            />
                                            <ContactField
                                                value={address.street}
                                                simplified={simplified}
                                                label={t("common:form.street")}
                                                onChange={(e: any) => {
                                                    dispatch({
                                                        type: ACTIONS.SET_SHIPPING_ADDRESS,
                                                        payload: {
                                                            address_index:
                                                                address_index,
                                                            key: "street",
                                                            value: e.target
                                                                .value,
                                                        },
                                                    });
                                                }}
                                            />
                                            <ContactField
                                                value={address.zip}
                                                simplified={simplified}
                                                label={t("common:form.zip")}
                                                onChange={(e: any) => {
                                                    dispatch({
                                                        type: ACTIONS.SET_SHIPPING_ADDRESS,
                                                        payload: {
                                                            address_index:
                                                                address_index,
                                                            key: "zip",
                                                            value: e.target
                                                                .value,
                                                        },
                                                    });
                                                }}
                                            />
                                            <ContactField
                                                value={address.city}
                                                simplified={simplified}
                                                label={t("common:form.city")}
                                                onChange={(e: any) => {
                                                    dispatch({
                                                        type: ACTIONS.SET_SHIPPING_ADDRESS,
                                                        payload: {
                                                            address_index:
                                                                address_index,
                                                            key: "city",
                                                            value: e.target
                                                                .value,
                                                        },
                                                    });
                                                }}
                                            />

                                            <CountrySelect
                                                value={address?.country}
                                                onChange={(value) => {
                                                    dispatch({
                                                        type: ACTIONS.SET_SHIPPING_ADDRESS,
                                                        payload: {
                                                            address_index:
                                                                address_index,
                                                            key: "country",
                                                            value: value,
                                                        },
                                                    });
                                                }}
                                                className={styles.input_item}
                                            />
                                            {/* <ContactField
												value={address.country}
												simplified={simplified}
												label={"Country"}
												onChange={(e: any) => {
													dispatch({
														type: ACTIONS.SET_SHIPPING_ADDRESS,
														payload: {
															address_index: address_index,
															key: "country",
															value: e.target.value
														}
													})
												}}
											/> */}
                                        </div>
                                        <Button
                                            className={
                                                styles.remove_address_button
                                            }
                                            color={"warning"}
                                            onClick={() => {
                                                dispatch({
                                                    type: ACTIONS.REMOVE_SHIPPING_ADDRESS,
                                                    payload: {
                                                        address_index:
                                                            address_index,
                                                    },
                                                });
                                            }}
                                        >
                                            <RemoveCircleIcon />
                                        </Button>
                                    </div>
                                );
                            }
                        )}
                        <Button
                            color={"success"}
                            size="small"
                            variant={"outlined"}
                            onClick={() => {
                                dispatch({
                                    type: ACTIONS.ADD_EMPTY_SHIPPING_ADDRESS,
                                    payload: {},
                                });
                            }}
                            fullWidth
                            className={styles.add_new_button}
                        >
                            {t("contacts:addNew")}
                        </Button>
                    </div>

                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            saveContact();
                        }}
                    >
                        {t("contacts:saveCompany")}
                    </Button>
                </div>
            </GenericDrawer>
        </>
    );
};

interface IContactField {
    value: string;
    simplified?: boolean;
    label: string;
    onChange: (e: any) => void;
    required?: boolean;
    customValidator?: () => boolean;
    fullWidth?: boolean;
}

const ContactField = ({
    value,
    simplified,
    label,
    onChange,
    required,
    customValidator,
    fullWidth,
}: IContactField) => {
    return !simplified || (simplified && required) ? (
        <GenericTextField
            value={value || ""}
            label={label}
            onChange={(e: any) => {
                onChange(e);
            }}
            required={required}
            className={styles.input_item}
            customValidation={customValidator}
            fullWidth={fullWidth}
        />
    ) : null;
};

export default AddCompany;
