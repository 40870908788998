import { useContext, createContext } from 'react';
import { makeSwrRequest } from './SwrProvider';
import { IUser } from '@/types/User';

const UsersContext = createContext({});

export const UsersProvider = ({children} : any) => {

	const { data: users } = makeSwrRequest(`/api/users`);

	return (
		<UsersContext.Provider value={{
			users
		}}>
			{children}
		</UsersContext.Provider>
	)
}

export const useUsers = () : any => useContext(UsersContext);