import { useState, useContext, createContext, useEffect, useMemo } from "react";

import { IMail } from "@/defs/Mails/Mails";
import { useMessages } from "./MessagesProvider";

const EmailsContext = createContext({});

export const EmailsProvider = ({ children }: any) => {
	const { showMessage } = useMessages();
	const sendEmail = (to: IMail['to'], subject: IMail['subject'], content: IMail['content'], attachment_ids?: IMail['attachment_ids']) => {
		return new Promise((resolve, reject) => {
			fetch(`/api/mails`, {
				method: 'POST',
				body: JSON.stringify({
					to: to,
					subject: subject,
					content: content,
					attachment_ids: attachment_ids || null
				})
			}).then(res => res.json()).then((data) => {
				resolve(data);
				showMessage("success", `Email was successfully sent to ${to}`);
			}).catch((e) => {
				reject(e);
			})
		})
	}

	const sendBulkEmail = (to: IMail['to'][], subject: IMail['subject'], content: IMail['content'], attachment_ids?: IMail['attachment_ids']) => {
		let finalData: any[] = [];
		if (to?.length > 0) {
			to?.forEach(async (address: string) => {
				await sendEmail(address, subject, content).then((data) => {
					finalData.push(data);
				})
			});
		}

		return finalData;
	}

	return (
		<EmailsContext.Provider value={{
			sendEmail,
			sendBulkEmail
		}}>
			{children}
		</EmailsContext.Provider>
	)

}

export const useEmails = (): any => useContext(EmailsContext);