import { Check } from "@mui/icons-material";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";

import styles from "@/styles/Issues/Issues.module.scss";
import { IIssue } from "@/types/Issue";
import { useAuth } from "@/utils/AuthProvider";
import { isUserAuthorizedToViewIssue, useIssues } from "@/utils/IssuesProvider";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDrawer from "../Generic/Drawer";
import GenericProgress from "../Generic/Progress";
import AddIssue from "./AddIssue";
import IssuesList from "./IssuesList";

interface IIssues {
    open: boolean;
    setOpen: (state: boolean) => void;
}

const Issues = ({ open, setOpen }: IIssues) => {
    const { t } = useTranslation(["common", "issues"]);
    const [addOpen, setAddOpen] = useState(false);

    const { userData } = useAuth();

    const {
        issues: issues_raw,
        isLoading,
        isError,
        mutate,
    }: {
        issues: IIssue[];
        isLoading: boolean;
        isError: any;
        mutate: any;
        isValidating: any;
    } = useIssues(open);

    useEffect(() => {
        mutate();
    }, [open, mutate]);

    // TODO: hide into a type
    const [issuesCategory, setIssuesCategory] = useState<
        "user" | "done" | "cancelled" | "all"
    >("user");
    const issues = useMemo(() => {
        return issues_raw?.filter((item: IIssue) => {
            const onlyActiveItems = item?.status === "in-progress";
            const onlyDoneItems = item?.status === "done";
            const onlyCancelledItems = item?.status === "cancelled";

            if (issuesCategory === "all") {
                return onlyActiveItems;
            } else if (issuesCategory === "done") {
                if (userData?.role === "admin") {
                    return onlyDoneItems;
                } else {
                    return (
                        onlyDoneItems &&
                        isUserAuthorizedToViewIssue(item, userData?.id)
                    );
                }
            } else if (issuesCategory === "cancelled") {
                return onlyCancelledItems;
            }
            return (
                onlyActiveItems &&
                isUserAuthorizedToViewIssue(item, userData?.id)
            );
        });
    }, [issues_raw, issuesCategory, userData?.id, userData?.role]);

    return (
        <>
            <AddIssue
                initialType="task"
                open={addOpen}
                setOpen={setAddOpen}
                onAdd={() => {
                    mutate();
                }}
            />
            <GenericDrawer
                open={open}
                setOpen={setOpen}
                title={t("common:info.cases")}
                titleButton={
                    <GenericButton
                        type={`add`}
                        onClick={() => {
                            setAddOpen(true);
                        }}
                        className={styles.add_issue_button}
                    />
                }
                askBeforeClose={false}
            >
                <div className={styles.issues_holder}>
                    {isLoading ? (
                        <GenericProgress />
                    ) : isError ? (
                        <>
                            {t("common:messages.loadingError")}{" "}
                            <Button
                                size={`small`}
                                variant={`outlined`}
                                onClick={() => {
                                    mutate();
                                }}
                            >
                                {t("common:actions.refresh")}
                            </Button>
                            <br />
                            <small>{isError.toString()}</small>
                        </>
                    ) : (
                        <>
                            <ToggleButtonGroup
                                exclusive
                                value={issuesCategory}
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    if (value !== null && value !== undefined) {
                                        setIssuesCategory(value);
                                    }
                                }}
                                fullWidth
                                color={`secondary`}
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "20px",
                                }}
                                size={`small`}
                            >
                                <ToggleButton value={`user`}>
                                    {t("issues:connectedMe")}
                                </ToggleButton>
                                {userData?.role === `admin` ? (
                                    <ToggleButton value={`all`}>
                                        {t("issues:allOpened")}
                                    </ToggleButton>
                                ) : null}
                                {userData?.role === `admin` ? (
                                    <ToggleButton value={`cancelled`}>
                                        {t("common:state.cancelledAt")}
                                    </ToggleButton>
                                ) : null}
                                <ToggleButton value={`done`}>
                                    <Check />
                                    {t("issues:doneIssues")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {issues?.length > 0 ? (
                                <IssuesList issues={issues} mutate={mutate} />
                            ) : (
                                t("issues:listEmpty")
                            )}
                        </>
                    )}
                </div>
            </GenericDrawer>
        </>
    );
};

export default Issues;
