import '@/styles/globals.scss';
import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/file-input/dist/style.min.css';
import '@uppy/progress-bar/dist/style.min.css';

import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import NextNProgress from 'nextjs-progressbar';

import { AuthProvider, useAuth } from 'utils/AuthProvider';
import { MessagesProvider } from '@/utils/MessagesProvider';
import { appWithTranslation } from 'next-i18next'
import { UsersProvider } from '@/utils/UsersProvider';
import { NotificationsProvider } from '@/utils/NotificationsProvider';
import Layout from '@/components/Layout';
import { EmailsProvider } from '@/utils/EmailsProvider';
import { LoadingProvider } from '@/utils/LoadingProvider';
import { BranchProvider, useBranch } from '@/utils/BranchProvider';

function MyApp({ Component, pageProps }: AppProps) {

	const theme = createTheme({
		palette: {
			primary: {
				main: 'rgba(33,4,0,1.0)',
			},
			secondary: {
				main: 'rgba(234, 86, 13, 1)',
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<NextNProgress color="#ea560d" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} />
			<MessagesProvider>
				<AuthProvider>
					<UsersProvider>
						<BranchProvider>
							<EmailsProvider>
								<NotificationsProvider>
									<LoadingProvider>
										<Layout>
											<Component {...pageProps} />
										</Layout>
									</LoadingProvider>
								</NotificationsProvider>
							</EmailsProvider>
						</BranchProvider>
					</UsersProvider>
				</AuthProvider>
			</MessagesProvider>
		</ThemeProvider >
	)
}

export default appWithTranslation(MyApp)
