import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useReducer, useState } from "react";

import { ACTIONS } from "@/defs/AddContact/AddPerson";
import styles from "@/styles/Contacts/AddContactDrawer.module.scss";
import { IPhone } from "@/types/Contact";
import { checkForValidPhone } from "@/utils/Contacts";
import { useMessages } from "@/utils/MessagesProvider";
import { validateEmail } from "@/utils/String";

import GenericButton from "../Generic/Buttons/GenericButton";
import CountrySelect from "../Generic/CountrySelect";
import GenericDrawer from "../Generic/Drawer";
import GenericPhoneInput from "../Generic/PhoneInput";
import GenericTextField from "../Generic/TextField";
import GenericSectionDivider from "./ContactSectionDivider";

interface IAddPerson {
    open: boolean;
    setOpen: (state: boolean) => void;
    onAdd?: (contact_data: any) => void;
}

const AddPerson = ({ open, setOpen, onAdd }: IAddPerson) => {
    const { t } = useTranslation(["common", "contacts"]);

    const [isContactInfoEdited, setIsContectInfoEdited] = useState(false);

    const personReducer = (state: any, action: any) => {
        switch (action.type) {
            case ACTIONS.SET_FIELD:
                return { ...state, [action.payload.key]: action.payload.value };
            case ACTIONS.SET_PHONE:
                return {
                    ...state,
                    ["phones"]: [
                        {
                            number: action.payload.number,
                            country_code: action.payload.country_code,
                        },
                    ],
                };
            case ACTIONS.SET_ADDRESS_FIELD:
                return {
                    ...state,
                    ["addresses"]: [
                        {
                            ...state.addresses[0],
                            type: "contact",
                            [action.payload.key]: action.payload.value,
                        },
                    ],
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(personReducer, {
        type: "individual",
        email: "",
        phones: [],
        addresses: [],
    });

    const { showMessage } = useMessages();

    const validateForm = () => {
        if (!state?.email && !state?.phones[0]?.number) {
            showMessage("error", t("contacts:contactRequired"));
            return false;
        }
        if (
            (!state?.email && !state?.phones[0]?.country_code) ||
            state?.phones[0]?.country_code?.toString() === 0
        ) {
            showMessage("error", t("contacts:codeRequired"));
            return false;
        }
        return true;
    };

    const saveContact = async () => {
        if (!validateForm()) {
            return;
        }

        const finalData = {
            ...state,
            phones: checkForValidPhone(state?.phones)
                ? state?.phones?.map((phone: IPhone) => {
                      return phone?.number && phone?.country_code
                          ? {
                                number: phone?.number,
                                country_code: phone?.country_code,
                            }
                          : null;
                  })
                : [],
        };

        fetch("/api/contacts", {
            method: "POST",
            body: JSON.stringify(finalData),
        })
            .then((res: any) => {
                return res.json();
            })
            .then((data_res: any) => {
                showMessage(
                    "success",
                    t("contacts:contactID", { id: data_res?.response?.id })
                );
                setOpen(false);
                if (onAdd) {
                    onAdd(data_res?.response);
                }
            })
            .catch((e: any) => {
                showMessage(
                    "error",
                    `${t("contacts:failed")}: ${e.toString()}`
                );
            });
    };

    const emailIsValid = useMemo(() => {
        return validateEmail(state?.email);
    }, [state?.email]);

    useEffect(() => {
        setIsContectInfoEdited(true);
    }, [state?.email, state?.phone]);

    useEffect(() => {
        setIsContectInfoEdited(false);
    }, []);

    return (
        <GenericDrawer open={open} setOpen={setOpen}>
            <div className={styles.manage_contact_holder}>
                <h2>Add person</h2>
                <div className={`${styles.contact_section_holder}`}>
                    <GenericSectionDivider title={t("contacts:personInfo")} />
                    <ContactField
                        value={state?.title}
                        label={t("contacts:title")}
                        onChange={(e: any) => {
                            dispatch({
                                type: ACTIONS.SET_FIELD,
                                payload: {
                                    key: "title",
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                    <ContactField
                        value={state?.first_name}
                        label={t("common:form.firstName")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_FIELD,
                                payload: {
                                    key: "first_name",
                                    value: e.target.value,
                                },
                            });
                        }}
                        required
                    />
                    <ContactField
                        value={state?.last_name}
                        label={t("common:form.lastName")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_FIELD,
                                payload: {
                                    key: "last_name",
                                    value: e.target.value,
                                },
                            });
                        }}
                        required
                    />
                    <ContactField
                        value={state?.degree}
                        label={t("common:form.degree")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_FIELD,
                                payload: {
                                    key: "degree",
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                </div>
                <div className={`${styles.contact_section_holder}`}>
                    <GenericSectionDivider title={t("contacts:contactInfo")} />
                    {/* <ContactField
							value={state?.phones[0]?.number}
							label={"Phone"}
							onChange={(e) => {
								dispatch({
									type: ACTIONS.SET_PHONE,
									payload: {
										number: e.target.value
									}
								})
							}}
							required={!isContactInfoEdited ? false : state?.email?.length === 0}
							customValidator={() => {
								return !isContactInfoEdited ? true : state?.phones[0]?.number?.length > 0 || (state?.email?.length > 0 && emailIsValid);
							}}
						/> */}
                    <span
                        className={styles.input_item}
                        style={{
                            width: "100%",
                        }}
                    >
                        <GenericPhoneInput
                            countryCodeValue={state?.phones[0]?.country_code}
                            phoneValue={state?.phones[0]?.number}
                            onChangeCountryCode={(e) => {
                                dispatch({
                                    type: ACTIONS.SET_PHONE,
                                    payload: {
                                        country_code: e,
                                        number: state?.phones[0]?.number,
                                    },
                                });
                            }}
                            onChangePhone={(e) => {
                                dispatch({
                                    type: ACTIONS.SET_PHONE,
                                    payload: {
                                        country_code:
                                            state?.phones[0]?.country_code,
                                        number: e.target.value,
                                    },
                                });
                            }}
                            required={
                                !isContactInfoEdited
                                    ? false
                                    : state?.email?.length === 0
                            }
                            customValidator={() => {
                                return !isContactInfoEdited
                                    ? true
                                    : state?.phones[0]?.number?.length > 0 ||
                                          (state?.email?.length > 0 &&
                                              emailIsValid);
                            }}
                        />
                    </span>
                    <ContactField
                        value={state?.email}
                        label={t("common:form.email")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_FIELD,
                                payload: {
                                    key: "email",
                                    value: e.target.value,
                                },
                            });
                        }}
                        required={
                            !isContactInfoEdited
                                ? false
                                : !state?.phones[0] ||
                                  state?.phones[0]?.number?.length === 0
                        }
                        customValidator={() => {
                            return !isContactInfoEdited
                                ? true
                                : state?.phones[0]?.number?.length > 0 ||
                                      (state?.email?.length > 0 &&
                                          emailIsValid);
                        }}
                    />

                    {!(!isContactInfoEdited
                        ? true
                        : state?.phones[0]?.number?.length > 0 ||
                          (state?.email?.length > 0 && emailIsValid)) ? (
                        <Box>
                            <small>Either phone or email is required</small>
                        </Box>
                    ) : null}
                </div>
                <div className={`${styles.contact_section_holder}`}>
                    <GenericSectionDivider title={t("common:form.adress")} />
                    <ContactField
                        value={state?.addresses[0]?.street}
                        label={t("common:form.street")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_ADDRESS_FIELD,
                                payload: {
                                    key: "street",
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                    <ContactField
                        value={state?.addresses[0]?.city}
                        label={t("common:form.city")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_ADDRESS_FIELD,
                                payload: {
                                    key: "city",
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                    <ContactField
                        value={state?.addresses[0]?.zip}
                        label={t("common:form.zip")}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.SET_ADDRESS_FIELD,
                                payload: {
                                    key: "zip",
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                    <CountrySelect
                        className={styles.input_item}
                        value={state?.addresses[0]?.country}
                        onChange={(value) => {
                            dispatch({
                                type: ACTIONS.SET_ADDRESS_FIELD,
                                payload: {
                                    key: "country",
                                    value: value,
                                },
                            });
                        }}
                    />
                    {/* <ContactField
							value={state?.addresses[0]?.country}
							label={"Country"}
							onChange={(e) => {
								dispatch({
									type: ACTIONS.SET_ADDRESS_FIELD,
									payload: {
										key: "country",
										value: e.target.value
									}
								})
							}}
						/> */}
                </div>
                <GenericButton
                    title={t("contacts:save")}
                    type={`save`}
                    onClick={() => {
                        saveContact();
                    }}
                />
            </div>
        </GenericDrawer>
    );
};

interface IContactField {
    value: string;
    simplified?: boolean;
    label: string;
    onChange: (e: any) => void;
    required?: boolean;
    customValidator?: () => boolean;
}

const ContactField = ({
    value,
    simplified,
    label,
    onChange,
    required,
    customValidator,
}: IContactField) => {
    return !simplified || (simplified && required) ? (
        <>
            <GenericTextField
                value={value || ""}
                label={label}
                onChange={(e: any) => {
                    onChange(e);
                }}
                required={required}
                className={styles.input_item}
                customValidation={customValidator}
            />
        </>
    ) : null;
};

export default AddPerson;
