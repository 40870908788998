import { Stack } from "@mui/system";
import { useTranslation } from "next-i18next";
import { useEffect, useReducer } from "react";

import { useFuelTypes } from "@/hooks/useFuelTypes";
import { ACTION, IEngine, IModel } from "@/types/Vehicle";

import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDialog from "../Generic/Dialog";
import GenericSelect from "../Generic/Select";
import GenericTextField from "../Generic/TextField";

const AddEngine = ({
    model,
    open,
    setOpen,
    onSave,
}: {
    model: IModel | null;
    open: boolean;
    setOpen: (state: boolean) => void;
    onSave: (data: IEngine) => void;
}) => {
    const { t } = useTranslation(["common", "vehicles"]);

    const defaultStateValues = {
        model_id: model?.id,
        name: "",
        capacity: "",
        fuel_type: "diesel",
        power: 0,
    };

    const fuelTypes = useFuelTypes();

    const engineReducer = (state: any, action: any) => {
        switch (action.type) {
            case ACTION.SET_FIELD:
                return { ...state, [action.payload.key]: action.payload.value };
            case ACTION.RESET_FORM:
                return defaultStateValues;
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(engineReducer, defaultStateValues);

    useEffect(() => {
        dispatch({
            type: ACTION.SET_FIELD,
            payload: {
                key: "model_id",
                value: model?.id,
            },
        });
    }, [model]);

    useEffect(() => {
        if (open === false) {
            dispatch({
                type: ACTION.RESET_FORM,
            });
        }
    }, [open]);

    const saveData = () => {
        fetch(`/api/vehicles/engines`, {
            method: "POST",
            body: JSON.stringify({
                model_id: state.model_id,
                name: state.name,
                capacity: null, //parseFloat(state.capacity),
                fuel_type: state.fuel_type,
                power: parseFloat(state.power),
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch({
                    type: ACTION.SET_FIELD,
                    payload: {
                        key: "name",
                        value: "",
                    },
                });
                setOpen(false);
                onSave(data?.response);
            });
    };

    return (
        <GenericDialog
            open={open}
            setOpen={setOpen}
            title={t("common:actions.addEngine")}
            showCloseButton
        >
            <Stack direction={`column`} spacing={2}>
                <GenericTextField
                    label={t("vehicles:engineName")}
                    value={state.name}
                    onChange={(e) => {
                        dispatch({
                            type: ACTION.SET_FIELD,
                            payload: {
                                key: "name",
                                value: e.target.value,
                            },
                        });
                    }}
                    fullWidth
                />
                <GenericTextField
                    label={t("common:info.capacity")}
                    value={state.capacity}
                    onChange={(e) => {
                        dispatch({
                            type: ACTION.SET_FIELD,
                            payload: {
                                key: "capacity",
                                value: e.target.value,
                            },
                        });
                    }}
                    fullWidth
                    endAdornment={`cc`}
                />
                <GenericTextField
                    label={t("vehicles:power")}
                    value={state.power}
                    onChange={(e) => {
                        dispatch({
                            type: ACTION.SET_FIELD,
                            payload: {
                                key: "power",
                                value: e.target.value,
                            },
                        });
                    }}
                    fullWidth
                    endAdornment={`kW`}
                />
                <GenericSelect
                    label={t("common:info.fuelType")}
                    data={fuelTypes}
                    value={state.fuel_type}
                    onChange={(value) => {
                        dispatch({
                            type: ACTION.SET_FIELD,
                            payload: {
                                key: "fuel_type",
                                value: value,
                            },
                        });
                    }}
                />
                <GenericButton
                    fullWidth
                    type="save"
                    title={t("common:actions.save")}
                    onClick={() => {
                        saveData();
                    }}
                />
            </Stack>
        </GenericDialog>
    );
};

export default AddEngine;
