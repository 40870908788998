import useSWR from "swr";

export const makeSwrRequest = <T>(
    url: string | null,
    method?: "GET" | "POST" | "PUT",
    options?: {
        refreshInterval?: number;
        disableAutoRefresh?: boolean;
    }
) => {
    if (!method) {
        method = "GET";
    }

    const finalOptions = {
        ...options,
        revalidateIfStale: !options?.disableAutoRefresh || true,
        revalidateOnFocus: !options?.disableAutoRefresh || true,
        revalidateOnReconnect: !options?.disableAutoRefresh || true,
    };
    delete finalOptions.disableAutoRefresh;

    const fetcher = async (url: string) => {
        const res = await fetch(url, {
            method: method,
        });
        const data = await res.json();
        return data;
    };

    const finalUrl = url?.replaceAll(" ", "");

    return useSWR<any>(finalUrl, fetcher, options);
};
