import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getRandomNumber } from "helpers/Numbers";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

interface IGenericAutoComplete {
    selected_value?: any;
    options: any[];
    label?: string;
    multiple?: boolean;
    loading?: boolean;
    noOptionText?: any;
    getOptionLabel?: (option: any) => string;
    renderOptionTitle?: (option: any) => string;
    onChange: (event: any, newValue: any) => void;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
    clearAfterSelect?: boolean;
    disabled?: boolean;
}

const GenericAutoComplete = ({
    label,
    selected_value,
    options,
    multiple,
    loading,
    noOptionText,
    getOptionLabel,
    renderOptionTitle,
    onChange,
    isOptionEqualToValue,
    clearAfterSelect,
    disabled,
}: IGenericAutoComplete) => {
    const [inputValue, setInputValue] = useState("");
    const { t } = useTranslation("common");
    if (!label) {
        label = t("common:message.selectOption");
    }

    //clear input if value is null or undefined
    useEffect(() => {
        if (selected_value === null || selected_value === undefined) {
            setInputValue("");
        }
    }, [selected_value]);

    return (
        <Autocomplete
            size={"small"}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            value={!clearAfterSelect ? selected_value : null}
            onChange={(event: any, newValue: string | null) => {
                if (newValue !== null) {
                    onChange(event, newValue);
                }
                setInputValue("");
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            loading={loading}
            loadingText={`${t("common:state.loading")}...`}
            noOptionsText={
                noOptionText ? (
                    <>{noOptionText}</>
                ) : (
                    t("common:message.noOption")
                )
            }
            options={options}
            sx={{ width: "100%" }}
            getOptionLabel={(option) => {
                if (getOptionLabel) {
                    return getOptionLabel(option);
                }
                return "";
            }}
            disabled={disabled}
            renderInput={(params) => <TextField {...params} label={label} />}
            renderOption={(props, option, { selected }) => {
                return (
                    <li
                        {...props}
                        key={`item-${option.id}-${getRandomNumber(10)}`}
                    >
                        {renderOptionTitle
                            ? renderOptionTitle(option)
                            : option.label}
                    </li>
                );
            }}
            isOptionEqualToValue={
                isOptionEqualToValue
                    ? isOptionEqualToValue
                    : (option, value) => {
                          return option.label == value.label;
                      }
            }
        />
    );
};

export default GenericAutoComplete;
