import { Button, MenuItem, Stack, TextField } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { CKEditor } from "ckeditor4-react";
import dayjs from "dayjs";
import { getContactName } from "helpers/Contacts";
import { getVehicleTitle } from "helpers/Vehicles";
import { useTranslation } from "next-i18next";
import { useEffect, useReducer, useState } from "react";

import { API_DATE_TIME_FORMAT } from "@/defs/global/global";
// import { LEAD_SOURCES } from "@/defs/Issues/LeadSources";
import { IJob } from "@/defs/Jobs/Job";
import useConnectionTypes, {
	IConnectionType,
} from "@/hooks/useConnectionTypes";
import useIssueTypes from "@/hooks/useIssueTypes";
import styles from "@/styles/Issues/AddIssue.module.scss";
import { fileTypes } from "@/types/FileManager";
import { ACTIONS, IIssue } from "@/types/Issue";
import { IUser } from "@/types/User";
import { useAuth } from "@/utils/AuthProvider";
import { useEmails } from "@/utils/EmailsProvider";
import { useLoading } from "@/utils/LoadingProvider";
import { useMessages } from "@/utils/MessagesProvider";
import { useNotifications } from "@/utils/NotificationsProvider";
import { makeSwrRequest } from "@/utils/SwrProvider";

import AddOwnerButton from "../Contacts/AddOwnerButton";
import GenericAutoComplete from "../Generic/AutoComplete";
import GenericButton from "../Generic/Buttons/GenericButton";
import GenericDatePicker from "../Generic/DatePicker";
import GenericDrawer from "../Generic/Drawer";
import AddVehicle from "../Vehicles/AddVehicle";

interface IAddIssue {
	open: boolean;
	setOpen: (state: boolean) => void;
	data?: IIssue;
	onAdd?: (issue: IIssue) => void;
	initialType?: string;
	initialCategoryId?: number | null;
	selectedDateFrom?: string;
	selectedDateTo?: string;
}

const AddIssue = ({
	open,
	setOpen,
	data,
	onAdd,
	initialType,
	initialCategoryId,
	selectedDateFrom,
	selectedDateTo,
}: IAddIssue) => {
	const appMessages = useMessages();
	const { sendBulkNotifications } = useNotifications();
	const { sendBulkEmail } = useEmails();
	const { showGlobalLoading, hideGlobalLoading } = useLoading();
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { t } = useTranslation(["common", "issues"]);
	const issueReducer = (state: any, action: any) => {
		switch (action.type) {
			case ACTIONS.SET_FIELD:
				setUnsavedChanges(true);
				return { ...state, [action.payload.key]: action.payload.value };
			case ACTIONS.SET_DEFAULT:
				return defaultIssueState;
			default:
				return state;
		}
	};

	const { userId } = useAuth();

	const defaultIssueState = data
		? {
			...data,
			author_id: data?.author?.id,
			category_id: data?.category?.id,
			maintainer_id: data?.maintainer?.id,
			assignee_ids: data?.assignees?.map((assignee: IUser) => {
				return assignee.id;
			}),
		}
		: {
			status: "in-progress",
			author_id: parseInt(userId),
			type: initialType || "task",
			category_id: initialCategoryId || null,
			maintainer_id: userId,
			datetime_to: null,
			connections: null,
		};

	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_FIELD,
			payload: {
				key: `maintainer_id`,
				value: userId,
			},
		});
	}, [userId]);

	const [state, dispatch] = useReducer(issueReducer, defaultIssueState);

	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_FIELD,
			payload: {
				key: "datetime_from",
				value: dayjs(selectedDateFrom).format(API_DATE_TIME_FORMAT),
			},
		});

		dispatch({
			type: ACTIONS.SET_FIELD,
			payload: {
				key: "datetime_to",
				value: dayjs(selectedDateTo).format(API_DATE_TIME_FORMAT),
			},
		});
	}, [selectedDateFrom, selectedDateTo]);

	const isReadyToSave = state?.maintainer_id && state?.subject?.length > 0;

	const saveData = () => {
		const finalData = {
			...state,
			content:
				!state?.content || state?.content?.length === 0
					? state?.subject
					: state?.content,
		};
		setIsSaving(true);
		showGlobalLoading();
		fetch("/api/issues", {
			method: "POST",
			body: JSON.stringify(finalData),
		})
			.then((res) => res.json())
			.then((data: any) => {
				if (!data?.response?.err) {
					appMessages.showMessage(
						"success",
						`${state?.type} (${data?.response?.subject}) ${t(
							"common:state.created"
						)}`
					);

					const notificationRecipients: number[] = [];
					const emailRecepients: string[] = [];
					notificationRecipients.push(state?.maintainer_id);
					if (
						state?.assignee_ids &&
						state?.assignee_ids?.length > 0
					) {
						state?.assignee_ids.forEach((assigneeId: number) => {
							notificationRecipients.push(assigneeId);
							const user = users?.find(
								(item: IUser) => item.id === assigneeId
							);
							if (user) {
								emailRecepients.push(user?.email);
							}
						});
					}

					if (notificationRecipients?.length > 0) {
						sendBulkNotifications(
							notificationRecipients,
							`${state?.type} ${t("issues:assigned")}`,
							data?.response?.subject,
							{
								issue_id: data?.response?.id,
							}
						);
					}

					if (emailRecepients?.length > 0) {
						const emailData = sendBulkEmail(
							emailRecepients,
							`${state?.type} ${t("issues:assigned")}`,
							`<b>${data?.response?.subject}</b><br/>${data?.response?.content}`
						);
					}

					if (onAdd) {
						onAdd(data);
					}
					setOpen(false);
					setIsSaving(false);
					hideGlobalLoading();
				} else {
					appMessages.showMessage(
						"error",
						`${t(
							"common:message.failed"
						)} ${data?.response.toString()}`
					);
					setIsSaving(false);
					hideGlobalLoading();
				}
			})
			.catch((e: any) => {
				appMessages.showMessage(
					"error",
					`${t("common:message.failed")}: ${e.toString()}`
				);
				setIsSaving(false);
				hideGlobalLoading();
			});
	};

	const updateIssueData = () => {
		const finalData = {
			...state,
			datetime_from: state?.datetime_from
				? dayjs(state?.datetime_from).format(API_DATE_TIME_FORMAT)
				: null,
			datetime_to: state?.datetime_to
				? dayjs(state?.datetime_to).format(API_DATE_TIME_FORMAT)
				: null,
			executorAcceptedAt: null,
			content:
				!state?.content || state?.content?.length === 0
					? state?.subject
					: state?.content,
			tasks: null,
		};

		setIsSaving(true);
		showGlobalLoading();
		fetch(`/api/issues/${state?.id}`, {
			method: "PUT",
			body: JSON.stringify(finalData),
		})
			.then((res) => res.json())
			.then((data: any) => {
				if (!data?.response?.err) {
					appMessages.showMessage(
						"success",
						`${t("issues:case")} (${data?.response?.subject}) ${t(
							"issues:updated"
						)}`
					);

					if (onAdd) {
						onAdd(data);
					}
					setOpen(false);
					setIsSaving(false);
					hideGlobalLoading();
				} else {
					appMessages.showMessage(
						"error",
						`${t(
							"common:message.failed"
						)}: ${data?.response.toString()}`
					);
					setIsSaving(false);
					hideGlobalLoading();
				}
			})
			.catch((e: any) => {
				appMessages.showMessage(
					"error",
					`${t("common:message.failed")}: ${e.toString()}`
				);
				setIsSaving(false);
				hideGlobalLoading();
			});
	};

	useEffect(() => {
		if (open === false) {
			dispatch({
				type: ACTIONS.SET_DEFAULT,
			});
			setIsSaving(false);
		}
	}, [open]);

	const issueTypes = useIssueTypes();

	const [issueConnectionType, setIssueConnectionType] =
		useState<fileTypes>(`none`);

	const { data: users } = makeSwrRequest(`/api/users`, `GET`);
	const { data: issueCategories } = makeSwrRequest(`/api/issue-categories`);
	const { data: allIssues } = makeSwrRequest(`/api/issues`, `GET`);
	const { data: allContacts, mutate: refreshContacts } = makeSwrRequest(
		`/api/contacts`,
		`GET`
	);
	const { data: allVehicles, mutate: refreshVehicles } = makeSwrRequest(
		`/api/vehicles`,
		`GET`
	);
	const { data: allJobs } = makeSwrRequest(`/api/jobs`, `GET`);

	const [openAddVehicle, setOpenAddVehicle] = useState(false);

	const [connectionValue, setConnectionValue] = useState<any>(null);

	const connectionTypes = useConnectionTypes();

	const LEAD_SOURCES = [
		{
			id: 1,
			value: "instagram",
			label: "Instagram",
		},
		{
			id: 2,
			value: "facebook",
			label: "Facebook",
		},
		{
			id: 3,
			value: "web",
			label: "Web",
		},
		// {
		// 	id: 4,
		// 	value: "configurator",
		// 	label: "Configurator",
		// },
		{
			id: 5,
			value: "email",
			label: "E-Mail",
		},
		{
			id: 6,
			value: "in-person",
			label: "In Person",
		},
		{
			id: 7,
			value: "other",
			label: t("issues:other"),
		},
	];

	const issueCategoriesOptions = [
		{
			id: null,
			label: t("common:job.general"),
		},
	].concat(
		issueCategories?.map((item: any) => {
			return {
				id: item?.id,
				label: item.name,
			};
		})
	);

	return (
		<>
			<GenericDrawer
				open={open}
				setOpen={setOpen}
				askBeforeClose={unsavedChanges}
				title={t("issues:addNewCase")}
			>
				{open ? (
					<div className={styles.add_issue_holder}>
						<div className={styles.field_holder}>
							<TextField
								select
								value={state.type}
								onChange={(e: any) => {
									dispatch({
										type: ACTIONS.SET_FIELD,
										payload: {
											key: "type",
											value: e.target.value,
										},
									});
								}}
								size="small"
								fullWidth
							>
								{issueTypes.map((type: any, index: number) => {
									return (
										<MenuItem
											key={`addIssueType-${index}`}
											value={type.key}
										>
											{type.title}
										</MenuItem>
									);
								})}
							</TextField>
						</div>
						{state.type === "task" ? (
							<div className={styles.field_holder}>
								<GenericAutoComplete
									selected_value={issueCategoriesOptions?.find(
										(item: any) =>
											item.id == state?.category_id
									)}
									label={t("issues:taskCategory")}
									options={issueCategoriesOptions}
									getOptionLabel={(option) => {
										return `${option.label}`;
									}}
									onChange={(event, newValue) => {
										dispatch({
											type: ACTIONS.SET_FIELD,
											payload: {
												key: "category_id",
												value: newValue.id,
											},
										});
									}}
									isOptionEqualToValue={(option, value) => {
										return option.id === value.id;
									}}
								/>
							</div>
						) : null}
						<div className={styles.field_holder}>
							{users ? (
								<GenericAutoComplete
									label={t("issues:maintainer")}
									selected_value={
										users?.find(
											(item: IUser) =>
												item.id === state?.maintainer_id
										) || ""
									}
									options={users}
									getOptionLabel={(option) => {
										return `${getContactName(option)}`;
									}}
									renderOptionTitle={(option) => {
										return `${getContactName(option)}`;
									}}
									onChange={(event, newValue) => {
										dispatch({
											type: ACTIONS.SET_FIELD,
											payload: {
												key: "maintainer_id",
												value: newValue.id,
											},
										});
									}}
									isOptionEqualToValue={(option, value) => {
										return option.id === value.id;
									}}
								/>
							) : null}
						</div>
						{state?.type !== "lead" ? (
							<>
								<div className={styles.field_holder}>
									<GenericAutoComplete
										label={t("issues:assignee")}
										multiple
										selected_value={users?.filter(
											(item: IUser) =>
												state?.assignee_ids?.includes(
													item.id
												)
										)}
										options={users}
										getOptionLabel={(option) => {
											return `${getContactName(option)}`;
										}}
										renderOptionTitle={(option) => {
											return `${getContactName(option)}`;
										}}
										onChange={(event, newValue) => {
											dispatch({
												type: ACTIONS.SET_FIELD,
												payload: {
													key: "assignee_ids",
													value: newValue.map(
														(assignee: any) => {
															return assignee.id;
														}
													),
												},
											});
										}}
										isOptionEqualToValue={(
											option,
											value
										) => {
											return option.id === value.id;
										}}
									/>
								</div>
								<div className={styles.field_holder}>
									<Stack direction={`row`} spacing={0.5}>
										<GenericDatePicker
											label={t("issues:startDate")}
											value={dayjs(
												state.datetime_from,
												API_DATE_TIME_FORMAT
											)}
											onChange={(value) => {
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "datetime_from",
														value: dayjs(
															value
														).format(
															API_DATE_TIME_FORMAT
														),
													},
												});
											}}
											className={styles.deadline_field}
										/>
										<Button
											size={"small"}
											onClick={() => {
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "datetime_from",
														value: dayjs().format(
															API_DATE_TIME_FORMAT
														),
													},
												});
											}}
										>
											{t("issues:now")}
										</Button>
									</Stack>
								</div>
								<div className={styles.field_holder}>
									{state.datetime_to !== null ? (
										<>
											<GenericDatePicker
												label={t("issues:endDate")}
												value={dayjs(
													state.datetime_to,
													API_DATE_TIME_FORMAT
												)}
												onChange={(value) => {
													dispatch({
														type: ACTIONS.SET_FIELD,
														payload: {
															key: "datetime_to",
															value: dayjs(
																value
															).format(
																API_DATE_TIME_FORMAT
															),
														},
													});
												}}
												className={
													styles.deadline_field
												}
											/>
											<Button
												size={"small"}
												onClick={() => {
													dispatch({
														type: ACTIONS.SET_FIELD,
														payload: {
															key: "datetime_to",
															value: null,
														},
													});
												}}
											>
												{t("issues:noDeadline")}
											</Button>
										</>
									) : (
										<Button
											size={"small"}
											onClick={() => {
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "datetime_to",
														value: dayjs().format(
															API_DATE_TIME_FORMAT
														),
													},
												});
											}}
										>
											{t("issues:addDeadline")}
										</Button>
									)}
								</div>
							</>
						) : (
							<>
								<div className={styles.field_holder}>
									<GenericAutoComplete
										label={t("issues:leadSource")}
										selected_value={
											LEAD_SOURCES?.find(
												(item) =>
													item.value === state?.source
											) || null
										}
										options={LEAD_SOURCES}
										onChange={(e, value) => {
											dispatch({
												type: ACTIONS.SET_FIELD,
												payload: {
													key: "source",
													value: value.value,
												},
											});
										}}
										getOptionLabel={(option) => {
											return option.label;
										}}
										renderOptionTitle={(option) => {
											return option.label;
										}}
									/>
								</div>
							</>
						)}

						<div className={styles.field_holder}>
							<p className={styles.section_heading}>
								{t("issues:caseConnected")}
							</p>
							<ToggleButtonGroup
								fullWidth
								color="primary"
								value={issueConnectionType || "none"}
								exclusive
								size={`small`}
								onChange={(e: any, value: fileTypes) => {
									if (value !== null && value !== undefined) {
										setIssueConnectionType(value);
										setConnectionValue(null);
										if (value === "none") {
											dispatch({
												type: ACTIONS.SET_FIELD,
												payload: {
													key: "connections",
													value: null,
												},
											});
										}
									}
								}}
								aria-label="Connection"
							>
								<ToggleButton value="none">
									{t("isses:none")}
								</ToggleButton>
								{connectionTypes?.map(
									(item: IConnectionType) => {
										return (
											<ToggleButton
												value={item.key}
												key={item.key}
											>
												{item.title}
											</ToggleButton>
										);
									}
								)}
							</ToggleButtonGroup>
						</div>

						<div className={styles.field_holder}>
							{issueConnectionType == "issue" ? (
								<>
									<GenericAutoComplete
										options={allIssues.map(
											(issue: IIssue) => {
												return {
													id: issue.id,
													label: issue.subject,
												};
											}
										)}
										getOptionLabel={(option) => {
											return `${option.label}`;
										}}
										onChange={(event, newValue) => {
											dispatch({
												type: ACTIONS.SET_FIELD,
												payload: {
													key: "connections",
													value: {
														[`${issueConnectionType}_id`]:
															newValue.id,
													},
												},
											});
										}}
									/>
								</>
							) : null}
							{issueConnectionType == "contact" ? (
								<>
									<Stack
										direction={`row`}
										spacing={2}
										alignItems={`center`}
									>
										<GenericAutoComplete
											selected_value={connectionValue}
											options={allContacts || []}
											getOptionLabel={(option) => {
												return `${getContactName(
													option
												)}`;
											}}
											renderOptionTitle={(option) => {
												return `${getContactName(
													option
												)}`;
											}}
											isOptionEqualToValue={(
												option,
												value
											) => {
												return option.id === value.id;
											}}
											onChange={(event, newValue) => {
												setConnectionValue(newValue);
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "connections",
														value: {
															[`${issueConnectionType}_id`]:
																newValue.id,
														},
													},
												});
											}}
										/>
										<AddOwnerButton
											onAdd={(contact) => {
												refreshContacts().then(() => {
													setConnectionValue(contact);
													dispatch({
														type: ACTIONS.SET_FIELD,
														payload: {
															key: "connections",
															value: {
																[`${issueConnectionType}_id`]:
																	contact.id,
															},
														},
													});
												});
											}}
										/>
									</Stack>
								</>
							) : null}
							{issueConnectionType == "vehicle" ? (
								<>
									<Stack
										direction={`row`}
										spacing={2}
										alignItems={`center`}
									>
										<GenericAutoComplete
											selected_value={connectionValue}
											options={allVehicles || []}
											getOptionLabel={(option) => {
												return `${getVehicleTitle(
													option
												)}`;
											}}
											renderOptionTitle={(option) => {
												return `${getVehicleTitle(
													option
												)}`;
											}}
											isOptionEqualToValue={(
												option,
												value
											) => {
												return option.id === value.id;
											}}
											onChange={(event, newValue) => {
												setConnectionValue(newValue);
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "connections",
														value: {
															[`${issueConnectionType}_id`]:
																newValue.id,
														},
													},
												});
											}}
										/>
										<GenericButton
											type="add"
											onClick={() => {
												setOpenAddVehicle(true);
											}}
										/>
									</Stack>
									<AddVehicle
										open={openAddVehicle}
										setOpen={setOpenAddVehicle}
										onAdd={(vehicle) => {
											refreshVehicles().then(() => {
												//fetchNewVehicle
												fetch(
													`/api/vehicles/${vehicle?.id}`
												)
													.then((res) => res.json())
													.then((vehicle_data) => {
														setConnectionValue(
															vehicle_data?.response
														);
													});
											});
										}}
									/>
								</>
							) : null}
							{issueConnectionType == "job" ? (
								<>
									<Stack
										direction={`row`}
										spacing={2}
										alignItems={`center`}
									>
										<GenericAutoComplete
											options={
												allJobs?.map((job: IJob) => {
													return {
														id: job.id,
														label: `${job?.source
															? job?.source
																?.subject
															: `${t(
																"issues:jobId"
															)} ${job?.id}`
															}`,
													};
												}) || []
											}
											getOptionLabel={(option) => {
												return `${option.label}`;
											}}
											onChange={(event, newValue) => {
												dispatch({
													type: ACTIONS.SET_FIELD,
													payload: {
														key: "connections",
														value: {
															[`${issueConnectionType}_id`]:
																newValue.id,
														},
													},
												});
											}}
										/>
									</Stack>
								</>
							) : null}
						</div>

						<div className={styles.field_holder}>
							<TextField
								fullWidth
								size="small"
								label={t("common:info.subject")}
								value={state?.subject || ""}
								onChange={(e: any) => {
									dispatch({
										type: ACTIONS.SET_FIELD,
										payload: {
											key: "subject",
											value: e.target.value,
										},
									});
								}}
							/>
						</div>

						<div className={styles.field_holder}>
							<p className={styles.section_heading}>
								{t("common:info.content")}
							</p>
							<CKEditor
								initData={state?.content || ""}
								onChange={(event: any) => {
									dispatch({
										type: ACTIONS.SET_FIELD,
										payload: {
											key: "content",
											value: event.editor.getData(),
										},
									});
								}}
							/>
						</div>
						{/* Content structured<br /> */}

						{/* <div className={styles.field_holder}>
						<p className={styles.section_heading}>Status</p>
						<ToggleButtonGroup
							fullWidth
							color="primary"
							value={state?.status || "none"}
							exclusive
							size={`small`}
							onChange={(e: any, value: string) => {
								dispatch({
									type: ACTIONS.SET_FIELD,
									payload: {
										key: "status",
										value: value,
									}
								});
							}}
							aria-label="Platform"
						>
							<ToggleButton value="in-progress">In Progress</ToggleButton>
							<ToggleButton value="done">Done</ToggleButton>
							<ToggleButton value="cancelled">Cancelled</ToggleButton>
						</ToggleButtonGroup>
					</div> */}

						<small>
							{!state?.maintainer_id && (
								<>
									{t("common:message.selectContainer")}
									<br />
								</>
							)}
							{state?.type === "lead" && (
								<>
									{t("common:message.selectConection")}
									<br />
								</>
							)}
							{!state?.subject ||
								(state?.subject?.length == 0 && (
									<>
										{t("common:message.enterSubject")}
										<br />
									</>
								))}
						</small>
						<br />
						<GenericButton
							type={`save`}
							onClick={() => {
								if (data) {
									updateIssueData();
									return;
								}
								saveData();
							}}
							title={t(
								`common:actions.${!data ? "save" : "update"}`
							)}
							disabled={!isReadyToSave || isSaving}
						/>
					</div>
				) : null}
			</GenericDrawer>
		</>
	);
};

export default AddIssue;
