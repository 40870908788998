export const ACTIONS = {
	SET_FIELD: "set_field",
	SET_MULTIPLE_FIELDS: "set_multiple_fields"
}

export type TAction = {
	type: string;
	payload: {
		key: string;
		value: any;
	}
}

export const BODY_TYPES = [
	{
		key: "suv",
		title: "SUV"
	},
	{
		key: "convertible",
		title: "Cabrio",
	},
	{
		key: "estate",
		title: "Combi"
	},
	{
		key: "van",
		title: "VAN"
	},
	{
		key: "coupe",
		title: "Coupe"
	},
	{
		key: "hatchback",
		title: "Hatchback"
	},
	{
		key: "saloon",
		title: "Sedan"
	},
	{
		key: "other",
		title: "Other"
	}
]