import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useTranslation } from "react-i18next";

const useIssueTypes = () => {
    const { t } = useTranslation(["issues", "common"]);

    const issueTypes = [
        {
            key: "task",
            title: t("issues:task"),
            color: "var(--task_color)",
            icon: <AssignmentIcon />,
        },
        {
            key: "lead",
            title: t("issues:lead"),
            color: "var(--lead_color)",
            icon: <AttachMoneyIcon />,
        },
        {
            key: "meeting",
            title: t("issues:meeting"),
            color: "var(--meeting_color)",
            icon: <GroupsIcon />,
        },
        {
            key: "phone-call",
            title: t("issues:call"),
            color: "var(--phoneCall_color)",
            icon: <LocalPhoneIcon />,
        },
        {
            key: "email",
            title: t("common:form.email"),
            color: "var(--mail_color)",
            icon: <AlternateEmailIcon />,
        },
    ];

    return issueTypes;
};

export default useIssueTypes;
